import { useState, useEffect } from 'react';
// @mui
import { DialogTitle, DialogContent, Dialog, IconButton, Stack, Button } from '@mui/material';
import { useSelector, useDispatch } from 'src/store';
import { remove } from 'src/store/reducers/drawer';

import { Logo } from 'src/components/logo';
import Iconify from 'src/components/iconify/iconify';

import { useResponsive } from 'src/hooks/use-responsive';

import JwtLoginView from './login-view';
import JwtRegisterView from './register-view';

// ----------------------------------------------------------------------

const AuthDialog = () => {
    const isMobile = useResponsive("only", "xs");

    const dispatch = useDispatch();
    const { name } = useSelector(state => state.drawer);

    const [componentToRender, setComponentToRender] = useState<JSX.Element>();
    const [activeItem, setActiveItem] = useState("");

    useEffect(() => {
        if (activeItem === "login" || (!activeItem && name === "login")) {
            setComponentToRender(<JwtLoginView setActiveItem={(value) => setActiveItem(value)} />);
        } else if (activeItem === "register" || (!activeItem && name === "register")) {
            setComponentToRender(<JwtRegisterView setActiveItem={(value) => setActiveItem(value)} />);
        }
    }, [name, activeItem]);

    const closeDialog = () => {
        dispatch(remove());
    };

    return (
        <Dialog
            open={name === "login" || name === "register"}
            maxWidth={isMobile ? "xl" : "xs"}
            scroll="paper"
            onClose={closeDialog}
            fullWidth
            fullScreen={isMobile && true}
            sx={{
                '& .MuiBackdrop-root': {
                    backgroundColor: "rgb(181 181 181 / 10%)"
                },
                '& .MuiPaper-root': {
                    backgroundColor: "black"
                }
            }}
        >
            <DialogTitle sx={{ height: "64px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Logo />
                <Stack direction="row" gap={1}>
                    {
                        (activeItem === "login" || (!activeItem && name === "login")) ?
                            <Button variant="contained" sx={{ textTransform: "uppercase" }} onClick={() => setActiveItem("register")}>Register</Button>
                            :
                            <Button variant="text" sx={{ textTransform: "uppercase" }} onClick={() => setActiveItem("login")}>Sign in</Button>
                    }
                    <IconButton color="inherit" edge="start" onClick={closeDialog} >
                        <Iconify icon="mingcute:close-line" />
                    </IconButton>
                </Stack>
            </DialogTitle>

            <DialogContent sx={{ px: { xs: 1.5, md: 3 }, mb: 2 }}>
                {componentToRender}
            </DialogContent>
        </Dialog>
    );
};

export default AuthDialog;