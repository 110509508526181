import { useState, useCallback } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Container, Stack, Typography, Divider, InputAdornment, IconButton, TextField, Tooltip, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useSettingsContext } from 'src/components/settings';
import Iconify from 'src/components/iconify/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
// hooks
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard';
import { useBoolean } from 'src/hooks/use-boolean';

import { enqueueSnackbar } from 'notistack';
import { deposit } from 'src/api/balance';

// ----------------------------------------------------------------------
type depositDataType = {
    address: string;
    label: string;
    qr_code: string;
};

const coins = [
    { label: "Binance Coin (BNB)", value: "BNB" },
    // { label: "Ethereum (ETH)", value: "ETH" },
    // { label: "Tron (TRX)", value: "TRX" },
    // { label: "Tether USD TRC20", value: "USDTTRC20" },
    // { label: "Tether USD ERC20", value: "USDTERC20" },
    // { label: "BitCoin (BTC)", value: "BTC" },
    // { label: "Test Coin (TCN)", value: "TCN" },
];

const DepositView = () => {
    const { copy } = useCopyToClipboard();

    const [coin, setCoin] = useState("BNB");
    const [isLoading, setIsLoading] = useState(false);
    const [depositData, setDepositData] = useState<depositDataType>();

    const onCopy = useCallback(
        (text: string) => {
            if (text) {
                enqueueSnackbar('Copied!');
                copy(text);
            }
        },
        [copy, enqueueSnackbar]
    );

    const onDeposit = async () => {
        try {
            if (coin !== "BNB" && coin !== "TCN") {
                enqueueSnackbar("You can't use this coin for now", { variant: 'warning' });
            } else {
                setIsLoading(true);

                const response = await deposit({ coin });
                if (response.flag === 1) {
                    setDepositData(response.data);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleCoinChange = (event: SelectChangeEvent) => {
        setCoin(event.target.value as string);
    };

    const renderForm = (
        <Stack spacing={2.5}>
            <Box>
                <Typography>
                    To make a deposit, please fill in all the required fields below. <br />
                    Note* Make sure to select the correct network in order to successfully complete the transaction. <br />
                    Please note that funds sent via the wrong network will be non-refundable. <br />
                    Also take into account the network fee, required for completing the transaction.
                </Typography>
            </Box>

            <Box>
                {depositData ?
                    <Stack justifyContent="center" gap={2} width={1}>
                        <Stack direction="row" justifyContent="center" width={1} gap={4}>
                            <Box width="50%">
                                <Typography mb={1}>One time wallet address</Typography>

                                <TextField
                                    fullWidth
                                    value={depositData.address}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Copy">
                                                    <IconButton onClick={() => onCopy(depositData.address)}>
                                                        <Iconify icon="eva:copy-fill" width={24} />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>

                            <Box
                                component="img"
                                src={depositData.qr_code}
                                width={200}
                                sx={{ borderRadius: 1 }}
                            />
                        </Stack>

                        <Typography variant='h6' align='center'>
                            Please deposit only once to the address displayed below no later than the time mentioned.
                        </Typography>
                    </Stack>
                    :
                    <Stack gap={2} width={{ md: "30%" }}>
                        <FormControl fullWidth sx={{ mt: 1 }}>
                            <InputLabel id="demo-simple-select-label">Crypto</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={coin}
                                label="Crypto"
                                onChange={handleCoinChange}
                                sx={{
                                    '& .MuiSelect-select': {
                                        display: "flex",
                                        alignItems: "center"
                                    }
                                }}
                            >
                                {coins.map((coinData, index) => <MenuItem key={index} value={coinData.value}>{coinData.label}</MenuItem>)}
                            </Select>
                        </FormControl>

                        <LoadingButton
                            color="primary"
                            variant="contained"
                            loading={isLoading}
                            sx={{
                                textTransform: "uppercase",
                                color: "black"
                            }}
                            onClick={() => onDeposit()}
                        >
                            Deposit
                        </LoadingButton>
                    </Stack>
                }
            </Box>
        </Stack>
    );

    return (
        <Stack
            direction="column"
            gap={0.5}
            sx={{
                p: 1,
                width: 1,
                borderRadius: 2,
                bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
                border: (theme) => `dashed 1px ${theme.palette.divider}`,
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h5" my={1}>Deposit</Typography>
            </Stack>

            <Divider sx={{ mb: 1 }} />

            {renderForm}
        </Stack>
    );
}

export default DepositView;