import { useState } from 'react';
import { m } from 'framer-motion';
import { useNavigate } from "react-router-dom";
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { useRouter } from 'src/routes/hooks';
import { useDispatch, useSelector } from 'src/store';
import { signout } from 'src/store/reducers/auth';
// components
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import AccountSettingDialog from 'src/sections/account';

import { fNumber } from "src/utils/format-number";
import { ASSETS_API, PATH_LOGIN, PATH_AFTER_LOGIN } from 'src/config-global';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: '/#1',
  },
  {
    label: 'Settings',
    linkTo: '/#2',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const { user, balance } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const popover = usePopover();

  const [accountDialogStatus, setAccountDialogStatus] = useState({ status: false, item: "" });

  const handleLogout = async () => {
    try {
      localStorage.clear();
      // @ts-ignore
      dispatch(signout());
      navigate(PATH_AFTER_LOGIN);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickItem = () => {
    setAccountDialogStatus({ status: true, item: "personal-detail" });
    popover.onClose();
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={user.avatar ? user.avatar : `${ASSETS_API}/assets/images/avatar/avatar_25.jpg`}
          alt={user?.username}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {user?.username.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5, cursor: "pointer" }} onClick={() => handleClickItem()}>
          <Typography variant="subtitle2" noWrap>
            {user?.username}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>

          <Typography variant="subtitle2" noWrap> Balance : &nbsp;
            {fNumber(balance || 0)}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Box sx={{ px: 2, py: 1.5 }}>
          <Typography variant="subtitle2" noWrap> Balance : &nbsp;
            {fNumber(balance || 0)}
          </Typography>

          <Typography variant="subtitle2" noWrap> Bonus : &nbsp;
            {fNumber(user.bonus || 0)}
          </Typography>
        </Box> */}

        <Stack sx={{ p: 1 }}>
          <MenuItem onClick={() => { router.push("/my-bets") }}>
            My Bets
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} /> */}

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Logout
        </MenuItem>
      </CustomPopover>

      <AccountSettingDialog
        status={accountDialogStatus.status}
        mainItem={accountDialogStatus.item}
        onClose={() => setAccountDialogStatus({ status: false, item: "" })}
      />
    </>
  );
}
