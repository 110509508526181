import { useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Divider, IconButton, Typography, Button } from '@mui/material';
import Drawer, { drawerClasses } from '@mui/material/Drawer';

import { useResponsive } from 'src/hooks/use-responsive';
import { useRouter } from 'src/routes/hooks';

// theme
import { paper } from 'src/theme/css';
import { useSettingsContext } from '../settings';
//
import Iconify from '../iconify';
import Scrollbar from '../scrollbar';

type PropsType = {
  status: boolean;
  closeModal: () => void;
}

const MainDrawer = ({ status, closeModal }: PropsType) => {
  const theme = useTheme();
  const router = useRouter();

  const settings = useSettingsContext();
  const isMini = settings.themeLayout === 'mini';
  const lgUp = useResponsive('up', 'lg');

  const handleClickMenu = (path: string) => {
    closeModal();
    router.push(path);
  };

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1, textTransform: "uppercase" }}>
        Menu
      </Typography>

      <IconButton onClick={closeModal}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  return (
    <Drawer
      anchor="top"
      open={status}
      // onClose={onCloseDrawer}
      slotProps={{
        backdrop: { invisible: true },
      }}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          ...paper({ theme, bgcolor: theme.palette.background.default }),
          width: 1,
          height: 1,
          maxWidth: 1
        },
      }}
    >
      {renderHead}

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Scrollbar sx={{ px: 2.5, py: 2, display: "flex", flexDirection: "column", gap: 1 }}>
        <Button
          variant="outlined"
          startIcon={<Iconify icon="pajamas:live-preview" />}
          sx={{ width: 1, justifyContent: "start" }}
          onClick={() => handleClickMenu("/live-sports")}
        >
          Live
        </Button>

        <Button
          variant="outlined"
          startIcon={<Iconify icon="material-symbols-light:sports-and-outdoors" />}
          sx={{ width: 1, justifyContent: "start" }}
          onClick={() => handleClickMenu("/sports")}
        >
          Sports
        </Button>

        <Button
          variant="outlined"
          startIcon={<Iconify icon="material-symbols:casino-outline" />}
          sx={{ width: 1, justifyContent: "start" }}
          onClick={() => handleClickMenu("/casino")}
        >
          Casino
        </Button>

        <Button
          variant="outlined"
          startIcon={<Iconify icon="streamline:toilet-women" />}
          sx={{ width: 1, justifyContent: "start" }}
          onClick={() => handleClickMenu("/live-casino")}
        >
          Live Casino
        </Button>

        <Button
          variant="outlined"
          startIcon={<Iconify icon="eos-icons:virtual-guest" />}
          sx={{ width: 1, justifyContent: "start" }}
          onClick={() => handleClickMenu("/virtual-sports")}
        >
          Virtual Sports
        </Button>
      </Scrollbar>
    </Drawer>
  );
}

export default MainDrawer;