import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Container, Stack, Typography, Divider, InputAdornment, IconButton, Alert, Grid } from '@mui/material';
// components
import { useSettingsContext } from 'src/components/settings';
import Iconify from 'src/components/iconify/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

import { useSelector } from 'src/store';
import { fNumber } from "src/utils/format-number";

// ----------------------------------------------------------------------

const PersonalDetailView = () => {
  const settings = useSettingsContext();
  const { user, balance } = useSelector((state) => state.auth);

  const [errorMsg, setErrorMsg] = useState('');

  const UserDataSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    name: Yup.string().required('Name required'),
  });

  const defaultValues = {
    name: '',
    email: ''
  };

  const methods = useForm({
    resolver: yupResolver(UserDataSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      console.log(data);
      // const response = await register(data);
      // console.log(response, "response")
      // if (response?.data) enqueueSnackbar("Registration successful", { variant: 'success' });
    } catch (error) {
      console.error(error);
      reset();
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const renderForm = (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={6}>
          <RHFTextField name="email" label="Email" value={user.email} />
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFTextField name="name" label="Name" value={user.username} />
        </Grid>

        <Grid item xs={12} md={6}>
          {/* @ts-ignore */}
          <RHFTextField name="balance" label="Balance" value={fNumber(balance || 0)} />
        </Grid>

        {/* <Grid item xs={12} md={6}>
          <RHFTextField name="bonus" label="Bonus" value={fNumber((user?.bonus || 0) + (user?.matchBonus || 0))}  />
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFTextField name="wagerAmount" label="Wager Requirement" value={fNumber(user.wagerAmount || 0)}  />
        </Grid> */}

        <Grid item xs={12} md={6}>
          {/* @ts-ignore */}
          <RHFTextField name="address" label="Address" value={user.address} />
        </Grid>

        <Grid item xs={12} md={6}>
          {/* @ts-ignore */}
          <RHFTextField name="phoneNumber" label="Phone Number" value={user.mobile} />
        </Grid>

        {/* <LoadingButton
          fullWidth
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Save
        </LoadingButton> */}
      </Grid>
    </FormProvider>
  );

  return (
    <Stack
      direction="column"
      gap={0.5}
      sx={{
        p: 1,
        width: 1,
        borderRadius: 2,
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
        border: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" my={1}>Personal Detail</Typography>
      </Stack>

      <Divider sx={{ mb: 1 }} />

      {renderForm}
    </Stack>
  );
}

export default PersonalDetailView;