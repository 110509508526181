import { useState } from 'react';
// @mui
import { Button, Divider, Stack } from '@mui/material';
// routes
import { RouterLink } from 'src/routes/components';
import { useDispatch } from 'src/store';
import { set } from 'src/store/reducers/drawer';
// config

// ----------------------------------------------------------------------

export default function LoginButton() {
  const dispatch = useDispatch();

  const openModal = (value: string) => {
    dispatch(set(value));
  };

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Button size="small" variant='outlined' component={RouterLink} onClick={() => openModal("login")}>SIGN IN</Button>&nbsp;&nbsp;
        <Button size="small" variant="contained" component={RouterLink} onClick={() => openModal("register")}>REGISTER</Button>
      </Stack>
      <Divider orientation='vertical' variant='middle' flexItem sx={{ my: 0.5 }} />
    </>
  );
}
