import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, DialogTitle, DialogContent, Dialog, IconButton, Grid, Container, Menu, MenuList, MenuItem, Stack } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { Logo } from 'src/components/logo';
import Iconify from 'src/components/iconify/iconify';

// ** Sports Components
import ActiveBets from 'src/pages/sports/my-bets';
import SettledBets from 'src/pages/sports/my-bets/settled';

import { useResponsive } from 'src/hooks/use-responsive';
import { useSelector } from 'src/store';
import { fNumber } from 'src/utils/format-number';

import { PasswordChangeView, PersonalDetailView } from './profile';
import { DepositView, WithdrawView } from './balance-management';
import { NewMessageView } from './message';

// ----------------------------------------------------------------------

const menuList = [
  {
    icon: "solar:user-circle-bold",
    text: "My Profile",
    value: "profile",
    children: [
      { text: "Personal Detail", value: "personal-detail" },
      { text: "Change Password", value: "change-password" }
    ]
  },
  {
    icon: "ri:money-dollar-circle-line",
    text: "Balance Management",
    value: "balance-management",
    children: [
      { text: "Deposit", value: "deposit" },
      { text: "Withdraw", value: "withdraw" },
      // { text: "Transaction History", value: "transaction-history" },
      // { text: "Info", value: "info" }
    ]
  },
  {
    icon: "ri:history-fill",
    text: "Bet History",
    value: "bet-history",
    children: [
      { text: "Active", value: "active_bet" },
      { text: "Settled", value: "settled_bet" }
    ]
  },
  // {
  //   icon: "mingcute:gift-line",
  //   text: "Bonuses",
  //   value: "bonus",
  //   children: [
  //     { text: "Sport Bonus", value: "sport-bonus" },
  //     { text: "Casino Bonus", value: "casino-bonus" },
  //     { text: "Bonus History", value: "bonus-history" }
  //   ]
  // },
  {
    icon: "lets-icons:message-duotone",
    text: "Messages",
    value: "message",
    children: [
      { text: "Inbox", value: "inbox" },
      { text: "Sent", value: "sent" },
      { text: "New", value: "new-message" }
    ]
  },
  // {
  //   icon: "solar:medal-ribbons-star-outline",
  //   text: "Rewards",
  //   value: "rewards"
  // }
];

const AccountSettingDialog = ({ status, mainItem, onClose }: { status: boolean, mainItem: string; onClose: () => void }) => {
  const isMobile = useResponsive("only", "xs");

  const [componentToRender, setComponentToRender] = useState<JSX.Element>();
  const [activeItem, setActiveItem] = useState<string>(isMobile ? "" : "personal-detail");

  useEffect(() => {
    if (mainItem && mainItem !== "personal-detail") {
      setActiveItem(mainItem);
    }
  }, [mainItem]);

  useEffect(() => {
    if (activeItem === 'personal-detail') {
      setComponentToRender(<PersonalDetailView />);
    } else if (activeItem === 'change-password') {
      setComponentToRender(<PasswordChangeView />);
    } else if (activeItem === 'new-message') {
      setComponentToRender(<NewMessageView />);
    } else if (activeItem === 'active_bet') {
      setComponentToRender(<ActiveBets />);
    } else if (activeItem === 'settled_bet') {
      setComponentToRender(<SettledBets />);
    } else if (activeItem === 'deposit') {
      setComponentToRender(<DepositView />)
    } else if (activeItem === 'withdraw') {
      setComponentToRender(<WithdrawView />)
    } else {
      setComponentToRender(<Box />);
    }
  }, [activeItem]);

  const closeDialog = () => {
    setActiveItem(isMobile ? "" : "personal-detail");

    if (!isMobile || (isMobile && activeItem === "")) {
      onClose();
    }
  };

  return (
    <Dialog
      open={status}
      maxWidth="xl"
      scroll="paper"
      onClose={closeDialog}
      fullWidth
      fullScreen={isMobile && true}
      sx={{
        "& .MuiPaper-root": {
          md: {
            height: "100%"
          }
        }
      }}
    >
      <DialogTitle sx={{ height: "64px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Logo sx={{ width: 'auto', mr: 2.5 }} />

        <IconButton color="inherit" edge="start" onClick={closeDialog}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ px: { xs: 1.5, md: 3 }, mb: 2 }}>
        <Grid container spacing={2}>
          {
            isMobile && activeItem ?
              <Box /> :
              <Grid item xs={12} md={3}>
                <MenuSection activeItem={activeItem} setActiveItem={(value: string) => setActiveItem(value)} />
              </Grid>
          }
          {
            isMobile && activeItem === "" ?
              <Box /> :
              <Grid item xs={12} md={9}>
                {componentToRender}
              </Grid>
          }
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(1),
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    position: 'absolute', right: theme.spacing(1)
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    //   marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: `4px ${theme.spacing(2)}`,
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const MenuSection = ({ activeItem, setActiveItem }: { activeItem: string, setActiveItem: (value: string) => void }) => {
  const { user, balance } = useSelector(state => state.auth);

  const [expanded, setExpanded] = useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      if (panel === "rewards") {
        setActiveItem(panel);
      }
    };

  return (
    <Stack direction="column" gap={1}>
      <Stack px={2} py={1} border={0.5} borderRadius={1} sx={{ backgroundColor: (theme) => theme.palette.success.main }}>
        <Stack direction="row" justifyContent="space-between" gap={1}>
          <Stack direction="column" width={1}>
            <Typography>Balance</Typography>
            {/* @ts-ignore */}
            <Typography variant='h6'>${fNumber(balance || 0)}</Typography>
          </Stack>
          {/* <Stack direction="column" width={1}>
            <Typography>Bonus</Typography>
            <Typography variant='h6'>${fNumber(user.bonus || 0)}</Typography>
          </Stack> */}
        </Stack>

        <Stack direction="row" gap={1}>
          <Button fullWidth variant="contained"
            startIcon={<Iconify icon="uil:money-insert" />}
            sx={{ backgroundColor: (theme) => theme.palette.success.light, color: "white", textTransform: "uppercase", "&:hover": { backgroundColor: (theme) => theme.palette.success.main } }}
            onClick={() => setActiveItem("deposit")}
          >Deposit</Button>
          <Button fullWidth variant="contained"
            startIcon={<Iconify icon="uil:money-withdraw" />}
            sx={{ backgroundColor: (theme) => theme.palette.success.light, color: "white", textTransform: "uppercase", "&:hover": { backgroundColor: (theme) => theme.palette.success.main } }}
            onClick={() => setActiveItem("withdraw")}
          >Withdraw</Button>
        </Stack>
      </Stack>

      <Stack direction="column" gap={0.5}>
        {menuList.map((menu) =>
          <Accordion key={menu.value} expanded={expanded === menu.value} onChange={handleChange(menu.value)}>
            <AccordionSummary>
              <Iconify icon={menu.icon} mr={1} />
              <Typography textTransform="uppercase">{menu.text}</Typography>
            </AccordionSummary>
            {menu.children && <AccordionDetails>
              <MenuList>
                {
                  menu.children.map(child =>
                    <MenuItem
                      key={child.value}
                      sx={{ ...(activeItem === child.value && { backgroundColor: (theme) => theme.palette.grey[900] }) }}
                      onClick={() => setActiveItem(child.value)}
                    >{child.text}</MenuItem>
                  )
                }
              </MenuList>
            </AccordionDetails>}
          </Accordion>
        )}
      </Stack>
    </Stack>
  )
}

export default AccountSettingDialog;