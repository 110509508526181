import { Avatar, Badge, BadgeProps, Box, Button, styled, Tab, Tabs, Theme, Typography } from '@mui/material';
import { BoxShadow } from 'src/config-global';
import { ReactNode } from 'react';
// import headerBackground from 'assets/images/landing/header-bg.jpg';
import Iconify from 'src/components/iconify';
import MainCard, { MainCardProps } from './cards/MainCard';
// import defaultColor from 'assets/scss/_themes-vars.module.scss';

interface MainStyleProps {
    theme: Theme;
    open: boolean;
    dWidth: number;
}

export const LoaderWrapper = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1301,
    width: '100%'
});

export const AuthCardWrapper = ({ children, ...other }: MainCardProps) => (
    <MainCard
        border={false}
        borderRadius="5px"
        sx={{
            background: "#FFFFFF1A",
            maxWidth: { xs: 400, lg: 475 },
            margin: { xs: 2.5, md: 3 },
            '& > *': {
                flexGrow: 1,
                flexBasis: '50%'
            }
        }}
        content={false}
        {...other}
    >
        <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>{children}</Box>
    </MainCard>
);

export const AuthWrapper = styled('div')(({ theme }) => ({
    background: `${theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary.light}80`,
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    overflow: 'auto',
    zIndex: 1300,
    backdropFilter: 'blur(5px)'
}));

export const HeaderWrapper = styled('div')(({ theme }) => ({
    // backgroundImage: `url(${headerBackground})`,
    backgroundSize: '100% 600px',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    paddingTop: 30,
    width: '100%',
    [theme.breakpoints.down('md')]: {
        paddingTop: 0
    }
}));

export const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'theme' && prop !== 'dWidth'
})(({ theme, open, dWidth }: MainStyleProps) => ({
    ...theme.typography.body1,
    padding: '0 !important',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${dWidth}px)`,
}));


export const Breadcrumbs = styled('div', {
    shouldForwardProp: (prop) => prop !== 'theme'
})(({ theme, background }: { theme: Theme; background: string | undefined }) => ({
    marginTop: '0.5rem',
    borderRadius: '18px',
    padding: '33px 34px',
    position: 'relative',
    overflow: 'hidden',
    transition: 'all 0.1s',
    // backgroundColor: defaultColor.dark4Background,
    boxShadow: BoxShadow,
    '& .text-box': {
        position: 'relative',
        zIndex: 2
    },
    '& .background': {
        position: 'absolute',
        width: '100%',
        right: '0px',
        top: '50%',
        zIndex: 1,
        transform: 'translateY(-50%)',
        opacity: 0.4
    },
    '& .trophy': {
        transform: 'rotate(24deg)',
        position: 'absolute',
        width: '200px',
        right: '15vw',
        top: '-30px',
        opacity: 0.7,
        zIndex: 1
    },
    '& h3': {
        fontWeight: '700',
        fontSize: '38px',
        lineHeight: '100%',
        color: 'white',
        marginBottom: '0.5rem'
    },
    '& .MuiTypography-body1': {
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '100%',
        opacity: 0.5,
        color: 'white'
    },
    '& .MuiTypography-body2': {
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '100%',
        color: 'white'
    },
    '& .light-1': {
        position: 'absolute',
        width: '156px',
        height: '156px',
        right: '10px',
        top: 0,
        // background: defaultColor.darkSecondaryMain,
        opacity: '0.5',
        filter: 'blur(100px)'
    },
    '& .light-2': {
        position: 'absolute',
        width: '488px',
        height: '488px',
        right: '0px',
        top: '-160px',
        // background: defaultColor.darkSecondaryMain,
        filter: 'blur(100px)'
    },
    '& .light-3': {
        // background: defaultColor.gradient2,
        boxShadow: '0px 4px 20px rgba(170, 98, 226, 0.45)',
        borderRadius: '10px',
        position: 'absolute',
        width: '10px',
        height: '10px',
        right: '5vw',
        zIndex: 11,
        bottom: '30px'
    },
    '& .light-4': {
        // background: defaultColor.gradient2,
        boxShadow: '0px 4px 20px rgba(170, 98, 226, 0.45)',
        borderRadius: '10px',
        position: 'absolute',
        width: '10px',
        height: '10px',
        right: '20vw',
        zIndex: 11,
        bottom: '50px'
    },
    '& .light-5': {
        // background: defaultColor.gradient2,
        boxShadow: '0px 4px 20px rgba(170, 98, 226, 0.45)',
        borderRadius: '10px',
        position: 'absolute',
        width: '10px',
        height: '10px',
        right: '10vw',
        zIndex: 11,
        top: '30px'
    },
    [theme.breakpoints.down('md')]: {
        padding: '20px 34px',
        '& .background': {
            minHeight: '100px'
        },
        '& .trophy': {
            width: '100px',
            right: '15vw',
            top: 0
        }
    }
}));

export const EventBreadcrumbs = styled('div', {
    shouldForwardProp: (prop) => prop !== 'theme'
})(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    marginTop: '0.5rem',
    marginBottom: '1rem',
    borderRadius: '18px',
    position: 'relative',
    overflow: 'hidden',
    transition: 'all 0.1s',
    // backgroundColor: defaultColor.dark4Background,
    boxShadow: BoxShadow,
    '& .css-nen11g-MuiStack-root': {
        position: 'relative',
        zIndex: 2
    },
    '& .background': {
        position: 'absolute',
        width: '100%',
        right: '0px',
        zIndex: 1,
        opacity: 0.7
    },
    '& .text-wrapper': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        zIndex: 12
    },
    '& .team-name': {
        marginTop: '10px',
        fontWeight: '500',
        lineHeight: '100%',
        fontSize: '15px',
        color: 'white',
        textAlign: 'center'
    },
    '& .light-1': {
        position: 'absolute',
        width: '156px',
        height: '156px',
        right: '10px',
        top: 0,
        // background: defaultColor.darkSecondaryMain,
        opacity: '0.5',
        filter: 'blur(100px)'
    },
    '& .light-2': {
        position: 'absolute',
        width: '488px',
        height: '488px',
        right: '0px',
        top: '-160px',
        // background: defaultColor.darkSecondaryMain,
        filter: 'blur(100px)'
    },
    '& .light-3': {
        position: 'absolute',
        width: '488px',
        height: '488px',
        left: '0px',
        top: '-160px',
        // background: defaultColor.darkSecondaryMain,
        filter: 'blur(100px)'
    },
    [theme.breakpoints.down('md')]: {
        '& .background': {
            minHeight: '100px'
        }
    }
}));

export const TeamName = styled(Typography)({
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '100%',
    color: 'white',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '150px',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    '@media (max-width: 900px)': {
        width: '100px'
    }
});

export const SmallTeamAvatar = styled(Avatar)({
    p: 0.5,
    border: '3px solid #3F4357',
    background: '#fff',
    cursor: 'pointer',
    position: 'absolute',
    zIndex: 2,
    width: 45,
    height: 45,
    '@media (max-width:350px)': {
        width: 35,
        height: 35
    }
});

export const TeamAvatar = styled(Avatar)({
    p: 0.5,
    // border: `3px solid ${defaultColor.dark3Background}`,
    background: 'white',
    cursor: 'pointer',
    width: 40,
    height: 40
});

export const OddWarraper = ({
    children,
    update,
    onClick,
    active,
    sx
}: {
    children: ReactNode;
    update: string | undefined;
    onClick?: any;
    active?: boolean;
    sx?: any;
}) => (
    <Button className={`arrow-${update}`} size='small' onClick={onClick} variant={active ? 'contained' : 'outlined'} sx={{
        fontSize: "11px !important",
        '&:hover': {
            // background: `rgba(${defaultColor.darkPrimaryMain}, 0.04)`,
            '& path': {
                fill: 'white'
            },
            '& .odd-num': {
                color: 'white'
            },
            '& .odd-attr': {
                color: 'white'
            }
        },
        '&:active': {
            transform: 'scale(0.95)'
        },
        // ...(active && {
        //     color: "white",
        //     background: '#2c7688 !important',
        // }),
        ...sx
    }}>
        {children}
    </Button >
);

export const Lock = () => (
    <>
        <Button>
            <Iconify icon="material-symbols:shield-lock" />
        </Button>
        <Button>
            <Iconify icon="material-symbols:shield-lock" />
        </Button>
    </>
);

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -13,
        top: 10
    }
}));

export const StatusBadge = ({ status }: { status: string }) => (
    <>
        {status === 'BET' && (
            <Badge
                color="primary"
                sx={{
                    '& .MuiBadge-badge': {
                        position: 'relative',
                        transform: 'unset',
                        textTransform: 'capitalize'
                    }
                }}
                badgeContent="Active"
            />
        )}
        {status === 'WIN' && (
            <Badge
                color="success"
                sx={{
                    '& .MuiBadge-badge': {
                        position: 'relative',
                        transform: 'unset',
                        textTransform: 'capitalize',
                        color: 'white'
                    }
                }}
                badgeContent="Won"
            />
        )}
        {status === 'LOST' && (
            <Badge
                color="error"
                sx={{
                    '& .MuiBadge-badge': {
                        position: 'relative',
                        transform: 'unset',
                        textTransform: 'capitalize',
                        color: 'white'
                    }
                }}
                badgeContent="Lose"
            />
        )}
        {status === 'HALF_WIN' && (
            <Badge
                color="success"
                sx={{
                    '& .MuiBadge-badge': {
                        position: 'relative',
                        transform: 'unset',
                        textTransform: 'capitalize',
                        color: 'white'
                    }
                }}
                badgeContent="Half Won"
            />
            // <>
            //     <Badge
            //         color="success"
            //         sx={{
            //             '& .MuiBadge-badge': {
            //                 position: 'relative',
            //                 transform: 'unset',
            //                 textTransform: 'capitalize',
            //                 color: 'white'
            //             }
            //         }}
            //         badgeContent={"Won"}
            //     />
            //     <Badge
            //         color="warning"
            //         sx={{
            //             '& .MuiBadge-badge': {
            //                 position: 'relative',
            //                 transform: 'unset',
            //                 textTransform: 'capitalize',
            //                 color: 'white',
            //                 background: defaultColor.darkTextSecondary
            //             }
            //         }}
            //         badgeContent={"Void"}
            //     />
            // </>
        )}
        {status === 'HALF_LOST' && (
            <Badge
                color="error"
                sx={{
                    '& .MuiBadge-badge': {
                        position: 'relative',
                        transform: 'unset',
                        textTransform: 'capitalize',
                        color: 'white'
                    }
                }}
                badgeContent="Half Lose"
            />
            // <>
            //     <Badge
            //         color="error"
            //         sx={{
            //             '& .MuiBadge-badge': {
            //                 position: 'relative',
            //                 transform: 'unset',
            //                 textTransform: 'capitalize',
            //                 color: 'white'
            //             }
            //         }}
            //         badgeContent={"Lose"}
            //     />
            //     <Badge
            //         color="warning"
            //         sx={{
            //             '& .MuiBadge-badge': {
            //                 position: 'relative',
            //                 transform: 'unset',
            //                 textTransform: 'capitalize',
            //                 color: 'white',
            //                 background: defaultColor.darkTextSecondary
            //             }
            //         }}
            //         badgeContent={"Void"}
            //     />
            // </>
        )}
        {status === 'REFUND' && (
            <Badge
                color="warning"
                sx={{
                    '& .MuiBadge-badge': {
                        position: 'relative',
                        transform: 'unset',
                        textTransform: 'capitalize',
                        color: 'white',
                        // background: defaultColor.darkTextSecondary
                    }
                }}
                badgeContent="Refund"
            />
        )}
        {status === 'CANCEL' && (
            <Badge
                color="warning"
                sx={{
                    '& .MuiBadge-badge': {
                        position: 'relative',
                        transform: 'unset',
                        textTransform: 'capitalize',
                        color: 'white',
                        // background: defaultColor.darkTextSecondary
                    }
                }}
                badgeContent="Cancel"
            />
        )}
        {status === 'SETTLED' && (
            <Badge
                color="warning"
                sx={{
                    '& .MuiBadge-badge': {
                        position: 'relative',
                        transform: 'unset',
                        textTransform: 'capitalize',
                        color: 'white',
                        // background: defaultColor.darkTextSecondary
                    }
                }}
                badgeContent="Settled"
            />
        )}
    </>
);

export const WTabs = styled(Tabs)({
    marginTop: '8px',
    marginBottom: '8px',
    minHeight: '45px',
    display: 'inline-flex',
    border: 'none',
    '& .MuiTabs-flexContainer': {
        boxShadow: BoxShadow,
        border: 'none',
        // background: defaultColor.darkLevel2,
        borderRadius: '100px',
        padding: '6px',
        overflow: 'hidden'
    },
    '& .MuiTabs-indicator': {
        display: 'none',
        height: 0
    }
});

export const WTab = styled(Tab)({
    minHeight: '40px',
    color: 'white',
    opacity: 0.5,
    '&.Mui-selected': {
        boxShadow: BoxShadow,
        // background: defaultColor.darkLevel1,
        borderRadius: '100px',
        overflow: 'hidden',
        color: 'white',
        opacity: 1
    }
});
export const SportsItem = styled('div', {
    shouldForwardProp: (prop) => prop !== 'theme' && prop !== 'active'
})(({ theme, index, active }: { theme: Theme; index: number; active: boolean }) => ({
    width: '70px',
    marginBottom: '1rem  !important',
    '& .warraper': {
        cursor: 'pointer',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        width: '60px',
        minWidth: '60px',
        height: '60px',
        marginLeft: '5px',
        overflow: 'hidden',
        borderRadius: '17px',
        ':active': {
            border: '2px solid rgba(255, 255, 255, 0.7)',
            '& .cover': {
                transform: 'scale(.5)',
                '& .back': {
                    width: '100%',
                    height: '100%',
                    opacity: '0.5'
                }
            }
        },
        ...(active && {
            border: '2px solid rgba(255, 255, 255, 0.7)',
            '& .cover .back': {
                width: '100%  !important',
                height: '100%  !important',
                opacity: '0.5'
            }
        }),
        ...(index === 0 && {
            background: theme.palette.grey[100],
            boxShadow: `3px 3px 15px -5px ${theme.palette.grey[100]}`
        }),
        ...(index === 1 && {
            background: theme.palette.grey[200],
            boxShadow: `3px 3px 15px -5px ${theme.palette.grey[200]}`
        }),
        ...(index === 2 && {
            background: theme.palette.grey[300],
            boxShadow: `3px 3px 15px -5px ${theme.palette.grey[300]}`
        }),
        ...(index === 3 && {
            background: theme.palette.grey[400],
            boxShadow: `3px 3px 15px -5px ${theme.palette.grey[400]}`
        }),
        ...(index === 4 && {
            background: theme.palette.grey[500],
            boxShadow: `3px 3px 15px -5px ${theme.palette.grey[500]}`
        }),
        '& .cover': {
            position: 'relative',
            width: '100%',
            height: '100%',
            transition: 'all 0.3s',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .back': {
                width: 0,
                height: 0,
                background: 'white',
                transition: 'all 0.3s',
                borderRadius: '15px'
            },
            '& i': {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                fontSize: '28px'
            }
        }
    },
    '& p': {
        marginTop: '0.4rem',
        width: '70px',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inherit'
    }
}));