import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Container, Stack, Typography, Divider, InputAdornment, IconButton, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useSettingsContext } from 'src/components/settings';
import Iconify from 'src/components/iconify/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';

import { enqueueSnackbar } from 'notistack';
import { changePassword } from 'src/api/user';

// ----------------------------------------------------------------------

const NewMessageView = () => {
  const [errorMsg, setErrorMsg] = useState('');

  const password = useBoolean();

  const MessageSchema = Yup.object().shape({
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Message is required')
  });

  const defaultValues = {
    subject: '',
    message: ''
  };

  const methods = useForm({
    resolver: yupResolver(MessageSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      console.log(data, "this is data");
      // const response = await changePassword(data);
      // if (response.status) enqueueSnackbar("Password changed successful", { variant: 'success' });
    } catch (error) {
      console.error(error);
      reset();
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const renderForm = (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={2.5}>
        {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

        <RHFTextField
          name="subject"
          label="Subject"
          type="text"
        />

        <RHFTextField
          name="message"
          label="Message"
          multiline
          rows={6}
        />

        <LoadingButton
          fullWidth
          color="success"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Submit
        </LoadingButton>
      </Stack>
    </FormProvider>
  );

  return (
    <Stack
      direction="column"
      gap={0.5}
      sx={{
        p: 1,
        width: 1,
        borderRadius: 2,
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
        border: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" my={1}>New Message</Typography>
      </Stack>

      <Divider sx={{ mb: 1 }} />

      {renderForm}
    </Stack>
  );
}

export default NewMessageView;