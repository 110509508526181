import { useEffect } from 'react';
// ** Main css
import './index.css';
// scrollbar
import 'simplebar-react/dist/simplebar.min.css';
// carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// image
import 'react-lazy-load-image-component/src/effects/blur.css';
// ----------------------------------------------------------------------

// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
import { useLocation } from 'react-router';
// ** Socket Part
import io from 'socket.io-client';
import { BASE_SOCKET_URL } from './config-global';
// ** Reducer Imports
import { useDispatch, useSelector } from './store';

import { ApiProvider } from './contexts/ApiContext';
import { UpdateBalance } from './store/reducers/auth';
// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isLoggedIn, token, balance } = useSelector((state) => state.auth);

  useEffect(() => {
    let socket = io(BASE_SOCKET_URL);
    if (isLoggedIn) {
      socket = io(BASE_SOCKET_URL, { query: { auth: token } });
      // socket.on("logout", () => {
      //   dispatch(Logout({}));
      // });
      socket.on("reload", () => {
        window.location.reload();
      });
      socket.on("balance", (data) => {
        if (!isLoggedIn) return;
        if (data.balance !== balance) {
          dispatch(UpdateBalance(data.balance));
        }
      });
      socket.on("msg sends", (data) => { });
    }
    return () => {
      if (socket) {
        socket.off("logout");
        socket.off("reload");
        socket.off("balance");
      }
    };
  }, [token, balance, isLoggedIn, dispatch]);
  // }, [token, balance, isLoggedIn, dispatch]);

  // useEffect(() => {
  //   const params = new URLSearchParams(window.location.search);
  //   const c = params.get("c");
  //   if (c) {
  //     dispatch(SetCode(c));
  //     dispatch(ChangePage("register"));
  //   }
  //   const b = params.get("b");
  //   if (b) {
  //     dispatch(SetBetsId(b));
  //     dispatch(ChangePage("bets"));
  //   }
  // }, [pathname, dispatch]);



  return (
    <SettingsProvider
      defaultSettings={{
        themeMode: 'dark', // 'light' | 'dark'
        themeDirection: 'ltr', //  'rtl' | 'ltr'
        themeContrast: 'default', // 'default' | 'bold'
        themeLayout: 'horizontal', // 'vertical' | 'horizontal' | 'mini'
        themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
        themeStretch: false,
      }}
    >
      <ThemeProvider>
        <MotionLazy>
          <SnackbarProvider>
            <SettingsDrawer />
            <ProgressBar />
            <ApiProvider>
              <Router />
            </ApiProvider>
          </SnackbarProvider>
        </MotionLazy>
      </ThemeProvider>
    </SettingsProvider>
  );
}
