export const MainColor = "#ffd700";
export const MainHoverColor = "#ffd700";
// -------------- League Part --------------
export const LeagueItemColor = "#ffffff1a";
export const LeagueInnerColor = "#1a1a1a";
export const LeaugeBorderColor = "#3c3c3c";
export const LeagueHoverColor = "#313131";
export const LeagueCtyColor = "#262626";
export const MatchCntColor = "#ffffff80";

// -------------- League Part --------------

// -------------- Events Part --------------
export const HeaderBgColor = "#ffffff26";
export const DateItemColor = "#ffffff0d";
export const OddsBtnColor = "#ffffff33";
export const EventTimeColor = "#ffffff80";
export const AmountBdColor = "#ffffff4d";
// -------------- Events Part --------------
// -------------- Markets Part --------------
export const HeaderColor = "#ffffff99";
export const HeaderBgColorM = "#ffffff26";
export const OddBtnBGColor = "#ffffff33";
// export const
// -------------- Markets Part --------------


// -------------- Markets Part --------------
export const MarketColor = "#ffffffa6";
export const TimeColor = "#ffffff66";
// -------------- Markets Part --------------

