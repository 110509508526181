import { useContext } from 'react';
import ApiContext from 'src/contexts/ApiContext';

const useApiContext = () => {
  const context = useContext(ApiContext);

  if (!context) throw new Error('context must be use inside provider');

  return context;
};

export default useApiContext;
