import moment from "moment";
// ** MUI Imports
import { Card, CardContent, CardHeader, IconButton, OutlinedInput, Stack, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
// ** Related Components Imports
import Transitions from "src/pages/sports/components/Transitions";
// ** Config Imports
import { LeagueItemColor, MainColor, MarketColor, TimeColor } from "src/types/colors";
// ** Types Imports
import { BetslipProps } from "src/types/sports";
// ** Utils Imports
import { addRemoveBetslip } from "src/utils/sports";
// ** Store Imports
import { useDispatch, useSelector } from "src/store";
import { setBetslip } from "src/store/reducers/sports";

interface propsTyps {
    betType: any,
    item: BetslipProps
}
const BetSlipItem = (props: propsTyps) => {
    const { betType, item } = props;
    const { betslipData } = useSelector(bitem => bitem.sports);
    const dispatch = useDispatch();

    const removeBetslip = () => {
        const betData = addRemoveBetslip(betslipData, item);
        dispatch(setBetslip(betData));
    }



    const singleAmountHandler = (paras: BetslipProps, value: any) => {
        const betslipdata = [...betslipData];
        for (const i in betslipdata) {
            if (betslipdata[i].id === paras.id && betslipdata[i].match_id === paras.match_id) {
                const data = { ...betslipdata[i], bet_amount: value };
                betslipdata[i] = data;
            }
        }
        dispatch(setBetslip([...betslipdata]));
    };



    return (
        <Transitions in direction="right" type="slide">
            <Card
                sx={{
                    borderRadius: '4px', mb: 0.5,
                    background: LeagueItemColor,
                }}
            >
                <CardHeader
                    sx={{
                        py: 0.5,
                        px: 1.5,
                        '& svg': {
                            fontSize: '18px'
                        },
                    }}
                    action={
                        <IconButton size="small" onClick={removeBetslip}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
                <CardContent sx={{ p: 1.5, pt: 0.5 }} style={{ paddingBottom: '12px' }}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography fontSize={12}>{item.selection}</Typography>
                        <Typography fontSize={12} color={MainColor}>{item.price}</Typography>
                    </Stack>
                    <Stack direction="row">
                        <Typography fontSize={12} color={MarketColor}>
                            {item.market_name}
                        </Typography>
                    </Stack>
                    <Stack direction="row">
                        <Typography fontSize={12} color={MarketColor}>{item.home_team}-{item.away_team}</Typography>
                    </Stack>
                    <Stack direction="row">
                        <Typography fontSize={11} color={TimeColor}>{moment(item.start_date).format('DD.MM.YY, hh:mm')}</Typography>
                    </Stack>
                    {betType.id === "single" &&
                        <Stack mt={0.5} gap={0.5}>
                            <OutlinedInput
                                fullWidth
                                id="betamount"
                                size="small"
                                type="number"
                                placeholder="Enter stake"
                                sx={{
                                    borderRadius: 1,
                                    '& fieldset': {
                                        borderRadius: 1
                                    }
                                }}

                                value={item.bet_amount ? item.bet_amount : ""}
                                onChange={(e) => singleAmountHandler(item, e.target.value)}
                            />
                            <Stack direction="row" justifyContent="space-between">
                                <Typography fontSize={12}>
                                    Possible win :
                                </Typography>
                                <Typography fontSize={12} color={MainColor}>
                                    {(item.bet_amount * item.price) ? item.bet_amount * item.price : 0}$
                                </Typography>
                            </Stack>
                        </Stack>
                    }
                </CardContent>
            </Card>
        </Transitions>
    );
}
export default BetSlipItem;