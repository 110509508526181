import axios from 'src/utils/axios';
import { API_PATH } from "src/config-global";

// ----------------------------------------------------------------------

export const getUserData = async () => {
  const URL = API_PATH.USER_GET_DATA;

  const response = await axios.post(URL);
  return response.data;
}

export const changePassword = async (payload: any) => {
  const URL = API_PATH.USER_CHANGE_PASSWORD;

  const response = await axios.post(URL, payload);
  return response.data;
}