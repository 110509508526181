import { useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Button, Divider, Stack, Toolbar } from '@mui/material';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
import { Logo } from 'src/components/logo';
import SvgColor from 'src/components/svg-color';

import { useSelector } from 'src/store';
//
import { HEADER, NAV } from '../config-layout';
import {
  Searchbar,
  AccountPopover,
  LoginButton,
  SettingsButton,
  LanguagePopover,
  IconMenu,
  ContactsPopover,
  NotificationsPopover,
  LoggedPopover
} from '../_common';

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

const Clock = () => {
  const [date, setDate] = useState(new Date());

  const tick = () => {
    setDate(new Date());
  };

  useEffect(() => {
    setInterval(() => tick(), 1000);
  }, []);
  return (
    <Box
      sx={{
        minWidth: 80,
        textAlign: 'right',
        cursor: "pointer",
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        px: 1,
        py: 0.5,
        borderRadius: 1
      }}>
      {date.toLocaleTimeString().replace("AM", "").replace("PM", "")}
    </Box>
  )
}

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();

  const { user, isLoggedIn } = useSelector((state) => state.auth);

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');
  const mdUp = useResponsive('up', 'md');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const renderContent = (
    <>
      <Logo sx={{ width: 'auto', mr: 2.5 }} />

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {isLoggedIn && mdUp && <LoggedPopover />}

        <Divider orientation='vertical' variant='middle' flexItem sx={{ my: 0.5 }} />

        {isLoggedIn ?
          <AccountPopover /> : <LoginButton />
        }

        <LanguagePopover />

        <Divider orientation='vertical' variant='middle' flexItem sx={{ my: 0.5 }} />

        {mdUp &&
          <>
            <Stack direction="row" alignItems="center">
              <Clock />
            </Stack>
          </>
        }

        {isLoggedIn && <IconMenu />}
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            // borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
