import { useState, useEffect, useCallback } from 'react';
// @mui
import { Theme, SxProps } from '@mui/material/styles';
import { Box, IconButton, Divider, Menu, MenuItem, Badge } from '@mui/material';

import Iconify from 'src/components/iconify/iconify';
import SettingDrawer from "src/components/setting";
import BetSlipDrawer from "src/sections/betslip";
import FavoriteDrawer from "src/sections/favorite";
import NotificationDrawer from "src/sections/notification";
// routes
import { RouterLink } from 'src/routes/components';
// config
import { PATH_LOGIN, PATH_REGISTER } from 'src/config-global';
import { useSelector } from 'src/store';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

const OPTIONS = [
  { icon: "iconamoon:notification-fill", value: "notification" },
  { icon: "mdi:star-outline", value: "favorites" },
  { icon: "lets-icons:setting-fill", value: "settings" },
  { icon: "typcn:document-text", value: "betslip" }
];

export default function IconMenu({ sx }: Props) {
  const [isOpen, setOpen] = useState<null | HTMLElement>(null);
  const [notificationModalStatus, setNotificationModalStatus] = useState(false);
  const [favoriteModalStatus, setFavoriteModalStatus] = useState(false);
  const [settingModalStatus, setSettingModalStatus] = useState(false);
  const [betSlipModalStatus, setBetSlipModalStatus] = useState(false);


  const { betslipData } = useSelector((state) => state.sports);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(null);
  }, []);

  const handleClickMenu = (value: string) => {
    if (value === "notification") {
      setNotificationModalStatus(true);
    } else if (value === "favorites") {
      setFavoriteModalStatus(true);
    } else if (value === "settings") {
      setSettingModalStatus(true);
    } else if (value === "betslip") {
      setBetSlipModalStatus(true);
    }

    handleClose();
  };

  return (
    <>
      {/* <Divider orientation='vertical' variant='middle' flexItem sx={{ my: 0.5 }} /> */}

      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ width: "44px", height: "44px" }}
      >
        <Iconify icon={isOpen ? "eva:more-horizontal-fill" : "eva:more-vertical-fill"} />
      </IconButton>

      <Menu
        id="lock-menu"
        anchorEl={isOpen}
        onClose={handleClose}
        open={Boolean(isOpen)}
        slotProps={{
          paper: {
            sx: {
              // px: 2,
              maxHeight: 60 * 4.5,
            },
          },
        }}
      >
        {OPTIONS.map((option, index) =>
          option.value !== "betslip" ?
            <MenuItem key={index} selected={option.value === ''} onClick={() => handleClickMenu(option.value)}>
              <Iconify icon={option.icon} />
            </MenuItem>
            :
            <Badge key={index} badgeContent={betslipData.length} color="success" sx={{ '& span': { left: "10px", top: "5px" } }} anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
              <MenuItem onClick={() => handleClickMenu(option.value)}>
                <Iconify icon={option.icon} />
              </MenuItem>
            </Badge>

        )}
      </Menu>

      <BetSlipDrawer status={betSlipModalStatus} closeModal={() => setBetSlipModalStatus(false)} />
      <SettingDrawer status={settingModalStatus} closeModal={() => setSettingModalStatus(false)} />
      <FavoriteDrawer status={favoriteModalStatus} closeModal={() => setFavoriteModalStatus(false)} />
      <NotificationDrawer status={notificationModalStatus} closeModal={() => setNotificationModalStatus(false)} />
    </>
  );
}
