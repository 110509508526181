// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------
export const API_URL = process.env.REACT_APP_API_URL;
export const BASE_URL = process.env.REACT_APP_ASSETS_URL;
// export const BASE_URL = process.env.REACT_APP_ASSETS_URL || "http://localhost:2024";
// export const BASE_SOCKET_URL = process.env.REACT_APP_SOCKET_API_URL;
export const BASE_SOCKET_URL = process.env.REACT_APP_SOCKET_API_URL || "http://localhost:2024";
// Timer
export const TIME = {
  timer1: 5000,
  timer2: 900000
}
export const BoxShadow = "rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.12) 0px 1px 2px 0px, rgba(255, 255, 255, 0.04) 0px 1px 0px 0px inset";
// PATH
export const API_PATH = {
  // auth
  AUTH_ROOT: 'auth',
  AUTH_REGISTER: 'auth/user-register',
  AUTH_LOGIN: 'auth/user-login',
  AUTH_CHECK: 'auth/check-auth',
  // game
  GAME_GET_TOP_LIST: 'casino/get-top-game-list',
  GAME_GET_LIST: 'casino/get-game-list',
  GAME_ACCESS_URL: 'casino/get-access-url',
  PROVIDER_GET_LIST: 'casino/get-provider-list',
  // game
  USER_GET_DATA: 'user/get-user-data',
  USER_CHANGE_PASSWORD: 'user/change-user-password',
  USER_BET_HISTORY: 'user/get-bet-history',
  // balance management
  DEPOSIT: 'balance/deposit',
  WITHDRAW: 'balance/withdraw'
};

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;




export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = `${paths.dashboard.root}/`; // as '/'
export const PATH_LOGIN = paths.auth.login; // as '/auth/login'
export const PATH_REGISTER = paths.auth.register; // as '/auth/login'
