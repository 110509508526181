import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Container, Stack, Typography, Divider, InputAdornment, IconButton, Alert, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useSettingsContext } from 'src/components/settings';
import Iconify from 'src/components/iconify/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';

import { enqueueSnackbar } from 'notistack';
import { withdraw } from 'src/api/balance';

// ----------------------------------------------------------------------
const coins = [
    { label: "Binance Coin (BNB)", value: "BNB" },
    // { label: "Ethereum (ETH)", value: "ETH" },
    // { label: "Tron (TRX)", value: "TRX" },
    // { label: "Tether USD TRC20", value: "USDTTRC20" },
    // { label: "Tether USD ERC20", value: "USDTERC20" },
    // { label: "BitCoin (BTC)", value: "BTC" },
    // { label: "Test Coin (TCN)", value: "TCN" },
];

const WithdrawView = () => {
    const [errorMsg, setErrorMsg] = useState('');
    const [coin, setCoin] = useState("BNB");

    const WithdrawDataSchema = Yup.object().shape({
        address: Yup.string().required('Address is required'),
        amount: Yup.number().required('Amount is required'),
    });

    const defaultValues = {
        address: '',
        amount: 0
    };

    const methods = useForm({
        resolver: yupResolver(WithdrawDataSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        try {
            if (coin !== "BNB" && coin !== "TCN") {
                enqueueSnackbar("You can't use this coin for now", { variant: 'warning' });
            } else {
                const requestData = { ...data, coin };
                const response = await withdraw(requestData);

                if (response?.falg === 1) enqueueSnackbar("Withdraw successful", { variant: 'success' });
            }
        } catch (error) {
            console.error(error);
            reset();
            setErrorMsg(typeof error === 'string' ? error : error.message);
        }
    });

    const handleCoinChange = (event: SelectChangeEvent) => {
        setCoin(event.target.value as string);
    };

    const renderForm = (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack spacing={2.5}>
                <Box>
                    <Typography>
                        To withdraw funds, please fill in all the required fields below.<br />
                        Note* Make sure to use the supported wallet address for the selected cryptocurrency to complete the transaction successfully.<br />
                        Please note that the stated network fee is approximate and may vary depending on asset price, network load, transaction time and date, etc.
                    </Typography>
                </Box >

                <Stack
                    gap={1}
                    width={{ md: "30%" }}
                >
                    {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
                    <FormControl fullWidth sx={{ mt: 1 }}>
                        <InputLabel id="demo-simple-select-label">Crypto</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={coin}
                            label="Crypto"
                            onChange={handleCoinChange}
                            sx={{
                                '& .MuiSelect-select': {
                                    display: "flex",
                                    alignItems: "center"
                                }
                            }}
                        >
                            {coins.map((coinData, index) => <MenuItem key={index} value={coinData.value}>{coinData.label}</MenuItem>)}
                        </Select>
                    </FormControl>

                    {/* @ts-ignore */}
                    <RHFTextField name="address" label="Address" />

                    {/* @ts-ignore */}
                    <RHFTextField name="amount" type="number" label="Amount" />

                    <LoadingButton
                        fullWidth
                        color="primary"
                        variant="contained"
                        type="submit"
                        loading={isSubmitting}
                        sx={{
                            textTransform: "uppercase",
                            color: "black"
                        }}
                    >
                        Withdraw
                    </LoadingButton>
                </Stack>
            </Stack >
        </FormProvider>
    );

    return (
        <Stack
            direction="column"
            gap={0.5}
            sx={{
                p: 1,
                width: 1,
                borderRadius: 2,
                bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
                border: (theme) => `dashed 1px ${theme.palette.divider}`,
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h5" my={1}>Withdraw</Typography>
            </Stack>

            <Divider sx={{ mb: 1 }} />

            {renderForm}
        </Stack>
    );
}

export default WithdrawView;