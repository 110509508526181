import { useState } from 'react';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, Typography, Button, Badge } from '@mui/material';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { usePathname, useRouter } from 'src/routes/hooks';
// components
import { useSettingsContext } from 'src/components/settings';
import Iconify from 'src/components/iconify/iconify';
import MenuDrawer from "src/components/menu-drawer";
import BetSlipDrawer from "src/sections/betslip";

import { useSelector } from 'src/store';

import { HEADER, NAV } from '../config-layout';

// ----------------------------------------------------------------------

const MenuButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: '#7b7b7b',
  '&:hover': {
    backgroundColor: 'transparent',
    color: 'white'
  },
  '&.active': {
    color: 'white'
  }
}));

export default function Footer() {
  const theme = useTheme();
  const pathName = usePathname();
  const router = useRouter();

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { betslipData } = useSelector((state) => state.sports);

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const [menuModalStatus, setMenuModalStatus] = useState(false);
  const [betSlipModalStatus, setBetSlipModalStatus] = useState(false);
  // const paymentBtns = [
  //   { route: "", img: '/assets/images/footer/usdt_eth.png' },
  //   { route: "", img: '/assets/images/footer/bitcoin.png' },
  //   { route: "", img: '/assets/images/footer/tron.png' },
  //   { route: "", img: '/assets/images/footer/litecoin.png' },
  //   { route: "", img: '/assets/images/footer/usdt.jpg' },
  //   { route: "", img: '/assets/images/footer/bnb.png' },
  //   { route: "", img: '/assets/images/footer/fasttoken.png' },
  //   { route: "", img: '/assets/images/footer/tron_usdt.png' },
  //   { route: "", img: '/assets/images/footer/fulgurpay.png' },
  // ];

  const renderContent = (
    <Stack
      flexGrow={1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={{ xs: 0.5, sm: 1 }}
    >
      <MenuButton className={pathName.search("sports/pre-match") !== -1 ? 'active' : ''} sx={{ flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={() => router.push("/sports/live")}>
        {/* <Stack alignItems="center" justifyContent="center" width={pathName.search("sports/live") !== -1 ? "40px" : "30px"} height={pathName.search("sports/live") !== -1 ? "40px" : "30px"} sx={{ mb: (pathName.search("sports/live") !== -1 ? 1 : 0), borderRadius: "50%", background: (pathName.search("sports/live") !== -1 ? theme.palette.primary.main : "") }}> */}
        <Iconify icon="svg-spinners:clock" width="30px" height="30px" />
        {/* </Stack> */}
        <Typography fontSize={10} textTransform='uppercase'>Live</Typography>
      </MenuButton>
      <MenuButton className={pathName.search("sports/pre-match") !== -1 ? 'active' : ''} sx={{ flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={() => router.push("/sports/pre-match")}>
        {/* <Stack alignItems="center" justifyContent="center" width={pathName.search("sports/pre-match") !== -1 ? "40px" : "30px"} height={pathName.search("sports/pre-match") !== -1 ? "40px" : "30px"} sx={{ mb: (pathName.search("sports/pre-match") !== -1 ? 1 : 0), borderRadius: "50%", background: (pathName.search("sports/pre-match") !== -1 ? theme.palette.primary.main : "") }}> */}
        <Iconify icon="material-symbols-light:sports-and-outdoors" width="30px" height="30px" />
        {/* </Stack> */}
        <Typography fontSize={10} textTransform='uppercase'>Sports</Typography>
      </MenuButton>
      {pathName.search("sports") !== -1 ?
        <Badge badgeContent={betslipData.length} color="success" sx={{ '& span': { left: "10px", top: "5px" } }} anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
          <MenuButton sx={{ flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={() => setBetSlipModalStatus(true)}>
            <Iconify icon="system-uicons:document-list" width="30px" height="30px" />
            <Typography fontSize={10} textTransform='uppercase'>BetSlip</Typography>
          </MenuButton>
        </Badge> :
        <MenuButton className={pathName === "/live-casino" ? 'active' : ''} sx={{ flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={() => router.push("/live-casino")}>
          {/* <Stack alignItems="center" justifyContent="center" width={pathName === "/live-casino" ? "40px" : "30px"} height={pathName === "/live-casino" ? "40px" : "30px"} sx={{ mb: (pathName === "/live-casino" ? 1 : 0), borderRadius: "50%", background: (pathName === "/live-casino" ? theme.palette.primary.main : "") }}> */}
          <Iconify icon="streamline:toilet-women" width="30px" height="30px" />
          {/* </Stack> */}
          <Typography fontSize={10} width="115%" textTransform='uppercase'>Live Casino</Typography>
        </MenuButton>
      }
      <MenuButton className={pathName === "/casino" ? 'active' : ''} sx={{ flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={() => router.push("/casino")}>
        {/* <Stack alignItems="center" justifyContent="center" width={pathName === "/casino" ? "40px" : "30px"} height={pathName === "/casino" ? "40px" : "30px"} sx={{ mb: (pathName === "/casino" ? 1 : 0), borderRadius: "50%", background: (pathName === "/casino" ? theme.palette.primary.main : "") }}> */}
        <Iconify icon="material-symbols:casino-outline" width="30px" height="30px" />
        {/* </Stack> */}
        <Typography fontSize={10} textTransform='uppercase'>Casino</Typography>
      </MenuButton>
      <MenuButton sx={{ flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={() => setMenuModalStatus(true)}>
        <Iconify icon="ion:menu" width="30px" height="30px" />
        <Typography fontSize={10} textTransform='uppercase'>Menu</Typography>
      </MenuButton>
    </Stack>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_DESKTOP_OFFSET,
        zIndex: theme.zIndex.appBar + 1,
        top: 'calc(100% - 64px)',
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
          borderTop: 'solid 1px'
        }}
      >
        {renderContent}
      </Toolbar>

      <BetSlipDrawer status={betSlipModalStatus} closeModal={() => setBetSlipModalStatus(false)} />
      <MenuDrawer status={menuModalStatus} closeModal={() => setMenuModalStatus(false)} />
    </AppBar>
  );
}
