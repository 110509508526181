import { MenuProps } from "src/types/menu";
import { createSlice } from "@reduxjs/toolkit";

const initialState: MenuProps = {
    selectedItem: [],
    drawerOpen: true,
    sidebarOpen: false,
    page: null,
    sportsmenu: true,
    chatOpen: false,
    leagueItem: {
        id: "",
        iso_code: "",
        label: "",
        region: "",
        sport_id: ""
    }
};

const menu = createSlice({
    name: "menu",
    initialState,
    reducers: {
        activeItem(state, action) {
            state.selectedItem = action.payload;
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload;
        },

        ChangePage(state, action: any) {
            state.page = action.payload;
        },
        ChangeLeague(state, action) {
            state.leagueItem = action.payload;
        },
        openSidebar(state, action: any) {
            state.sidebarOpen = action.payload;
        },

        changeSportsMenu(state, action) {
            state.sportsmenu = action.payload;
        },

        changeChatOpen(state, action: any) {
            state.chatOpen = action.payload;
        },
    },
});

export default menu.reducer;

export const {
    activeItem,
    openDrawer,
    ChangePage,
    ChangeLeague,
    openSidebar,
    changeSportsMenu,
    changeChatOpen,
} = menu.actions;
