import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import AuthGuard from 'src/utils/auth-guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const PreSports = lazy(() => import('src/pages/sports/pre-match'));
const UpcomingPage = lazy(() => import('src/pages/sports/upcoming'));
const MyBets = lazy(() => import('src/pages/sports/my-bets'));
const Settled = lazy(() => import('src/pages/sports/my-bets/settled'));
const LiveSports = lazy(() => import('src/pages/sports/live'));
const LiveCalendar = lazy(() => import('src/pages/sports/liveCalendar'));
// ----------------------------------------------------------------------

export const sportsRoutes = [
    {
        
        element: (
            // <AuthGuard>
            <DashboardLayout>
                <Suspense fallback={<LoadingScreen />}>
                    <Outlet />
                </Suspense>
            </DashboardLayout>
            // </AuthGuard>
        ),
        children: [
            // ** Pre-match
            { path: 'sports/live', element: <LiveSports /> },
            { path: 'sports/live/:event_id', element: <LiveSports /> },
            // ** Pre-match
            { path: 'sports/pre-match', element: <PreSports /> },
            { path: 'sports/pre-match/:sport_id/:league_id', element: <PreSports /> },
            { path: 'sports/pre-match/:sport_id/:league_id/:event_id', element: <PreSports /> },
            // ** Upcoming matchs
            { path: 'sports/upcoming', element: <UpcomingPage /> },
            { path: 'sports/upcoming/:event_id', element: <UpcomingPage /> },
            // { path: 'sports/upcoming/:sportsId/:tabId', element: <UpcomingPage /> },
            // { path: 'sports/upcoming/:sportsId/:event_id', element: <UpcomingPage /> },

            { path: '/my-bets', element: <MyBets /> },
            { path: '/my-bets/settled', element: <Settled /> },
            // { path: 'sports/pre-match/live-calendar', element: <LiveCalendar /> },
            // { path: 'sports/live/event-view', element: <LiveSports /> },

            // {
            //   path: 'group',
            //   children: [
            //     { element: <PageFour />, index: true },
            //     { path: 'five', element: <PageFive /> },
            //     { path: 'six', element: <PageSix /> },
            //   ],
            // },
        ],
    },
];
