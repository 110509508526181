import { useState } from 'react';
import { m } from 'framer-motion';
// @mui
import { Button, Box, Stack, MenuItem } from '@mui/material';
// routes
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify/iconify';
import AccountSettingDialog from 'src/sections/account';
import { useSelector } from 'src/store';
import { fNumber } from 'src/utils/format-number';

// ----------------------------------------------------------------------
export const allLangs = [
  {
    label: 'Deposit',
    value: 'deposit',
    icon: 'bxs:dollar-circle',
  },
  {
    label: 'Withdraw',
    value: 'withdraw',
    icon: 'uil:money-withdraw',
  },
];

export default function LoggedPopover() {
  const popover = usePopover();

  const { user, balance } = useSelector(state => state.auth);

  const [accountDialogStatus, setAccountDialogStatus] = useState({ status: false, item: "" });

  const openAccountDialog = (value: string) => {
    setAccountDialogStatus({ status: true, item: value });
  };

  return (
    <>
      <Stack direction="row" alignItems="center" gap={2}>
        <Button
          variant="contained"
          size="small"
          startIcon={<Iconify icon="bxs:dollar-circle" />}
          onClick={() => openAccountDialog("deposit")}
        >DEPOSIT</Button>

        <Stack height={1} sx={{ backgroundColor: "green", borderRadius: 0.5 }}>
          <Iconify icon="f7:money-dollar" />
        </Stack>

        <Box>
          <MenuItem
            component={m.button}
            whileTap="tap"
            whileHover="hover"
            onClick={popover.onOpen}
            sx={{
              height: 40,
              '&:hover': {
                backgroundColor: '#17191b',

              },
              '&:not(:last-of-type)': {
                mb: 0,
              },
              ...(popover.open && {
                bgcolor: 'action.selected',
              }),
            }}
          >
            ${fNumber(balance || 0)}
          </MenuItem>

          <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }}>
            {allLangs.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => openAccountDialog(option.value)}
              >
                <Iconify icon={option.icon} sx={{ borderRadius: 0.65, width: 28 }} />

                {option.label}
              </MenuItem>
            ))}
          </CustomPopover>
        </Box>
      </Stack>

      <AccountSettingDialog
        status={accountDialogStatus.status}
        mainItem={accountDialogStatus.item}
        onClose={() => setAccountDialogStatus({ status: false, item: "" })}
      />
    </>
  );
}
