/* eslint-disable-next-line padded-blocks */
import { combineReducers } from 'redux';

import auth from './auth';
import drawer from './drawer';
import menu from './menu';
import sports from './sports';
import markets from './markets';

const reducer = combineReducers({
  auth,
  drawer,
  menu,
  sports,
  markets
});

export default reducer;
