import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTheme, Card, CardContent, CardHeader, Divider, Grid, IconButton, Skeleton, Stack, Typography, Box, Container } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';

import CopyToClipboard from 'react-copy-to-clipboard';

import moment from 'moment';

import { BASE_URL } from 'src/config-global';


import useApi from 'src/hooks/use-api';

import { toNumber } from 'src/utils/number';

import { StatusBadge, TeamAvatar, TeamName, WTab, WTabs } from 'src/pages/ui-component';
import Transitions from 'src/pages/sports/components/Transitions';
import OddNum from 'src/pages/sports/components/OddNum';
import { useSelector } from 'src/store';
import { americanOddsCalc } from 'src/utils/games';
import { MultibetIcon, MyBetsIcon, StatusIcon } from 'src/pages/ui-component/SvgIcon';
import { enqueueSnackbar } from 'notistack';

import { useSettingsContext } from 'src/components/settings';

const CheckIconStyle = {
    position: 'absolute',
    fontSize: '100px',
    color: '#02ff02',
    '@media (max-width:380px)': {
        fontSize: 60
    }
};

const MybetsActive = () => {
    const theme = useTheme();
    const settings = useSettingsContext();
    moment.locale("en");
    const Api = useApi();
    const navigate = useNavigate();
    const [activeOdds, setActiveOdds] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [history, setHistory] = useState([1, 2, 3, 4]);
    const { user } = useSelector((store) => store.auth);
    const userId = user?._id;


    // ** Router Part ( Active or Settled )
    const { pathname } = useLocation();
    const [activeTab, setActiveTab] = useState<number>(0);

    const tabChangeHandler = (event: React.SyntheticEvent, index: number) => {
        navigate(index === 0 ? '/my-bets' : '/my-bets/settled');
        setActiveTab(index);
    };

    // ** Router Part ( Active or Settled )


    const getMybets = () => {
        setLoading(true);
        Api.getMybets('Active')
            .then(({ data }) => {
                // setHistory(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    // const onActive = (id: string) => {
    //     const findIndex = activeOdds.indexOf(id);
    //     if (findIndex === -1) {
    //         setActiveOdds([...activeOdds, id]);
    //     } else {
    //         const data = [...activeOdds];
    //         data.splice(findIndex, 1);
    //         setActiveOdds([...data]);
    //     }
    // };

    // useEffect(() => {
    //     getMybets();
    //     // eslint-disable-next-line
    // }, []);

    // useEffect(() => {
    //     const path = document.location.pathname.toString();
    //     if (path === '/my-bets') {
    //         setActiveTab(0);
    //     }
    //     if (path === '/my-bets/settled') {
    //         setActiveTab(1);
    //     }
    // }, [pathname]);



    if (loading) return <Skeleton variant="rectangular" height={300} sx={{ borderRadius: '18px' }} />;
    return (
        <Container maxWidth={settings.themeStretch ? false : 'xl'}>
            <Grid item xs={12} py={1}>
                <Stack direction="row" alignItems="center" spacing={1} ml={1} mt={1}>
                    <MyBetsIcon />
                    <Typography
                        sx={{
                            color: 'white',
                            fontSize: '18px',
                            fontWeight: '700'
                        }}
                    >
                        My Bets
                    </Typography>
                </Stack>
                <WTabs sx={{ width: "fit-content" }} value={activeTab} onChange={tabChangeHandler} aria-label="icon">
                    <WTab label="Active" iconPosition="start" />
                    <WTab label="Settled" iconPosition="start" />
                </WTabs>
            </Grid>
            <Grid container spacing={1}>
                {history.map((item: any, key) => (
                    <Grid key={key} item xs={12} sm={6} lg={4}>
                        <Transitions in direction="left" type="slide">
                            <Card
                                sx={{
                                    mb: 1,
                                }}
                                style={{ borderRadius: '4px' }}
                            >
                                <CardHeader
                                    sx={{
                                        background: theme.palette.background.paper,
                                        p: 1.5,
                                        '& .MuiCardHeader-title': {
                                            fontSize: '14px'
                                        },
                                        '& svg': {
                                            fontSize: '16px'
                                        },
                                    }}
                                    title={
                                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                {/* {item.type === 'multi' ? ( */}
                                                <MultibetIcon />
                                                {/* ) : (
                                                    <i
                                                        className="sportsicons sportsicon-"
                                                        style={{ fontSize: '20px' }}
                                                    />
                                                )} */}
                                                <StatusBadge status="WIN" />
                                                {/* <StatusBadge status={item.status} /> */}
                                            </Stack>
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                {moment(item.createdAt).format('MMM DD, h:mm A')}
                                                <IconButton
                                                    // onClick={() => onActive(item?._id || '')} 
                                                    size="small">
                                                    {activeOdds.indexOf(item?._id || '') !== -1 ? (
                                                        <KeyboardArrowDownIcon />
                                                    ) : (
                                                        <KeyboardArrowLeftIcon />
                                                    )}
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                    }
                                />
                                <CardContent sx={{ background: theme.palette.background.neutral, p: 1.5 }} style={{ paddingBottom: '12px' }}>
                                    {activeOdds.indexOf(item?._id || '') !== -1 && (
                                        <Transitions in direction="left" type="slide">
                                            {(item.bettings as any[]).map((bettting, index) => (
                                                <Stack key={index}>
                                                    <Stack direction="row" alignItems="center" spacing={1}>
                                                        <StatusIcon status={bettting.status} />
                                                        <Typography
                                                            onClick={() => navigate(`/events/${bettting.eventId}`)}
                                                            sx={{ pt: 0.5, cursor: 'pointer' }}
                                                        >
                                                            {`${bettting.HomeTeam} - ${bettting.AwayTeam}`}
                                                        </Typography>
                                                    </Stack>
                                                    <Typography variant="body2">
                                                        {bettting.marketName && bettting.marketName}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Start in {moment(bettting?.Time).format('MMM DD, h:mm A')}
                                                    </Typography>

                                                    <Stack direction="row" justifyContent="space-between">
                                                        <Typography variant="body2" color="white">
                                                            {bettting.oddName}
                                                        </Typography>
                                                        <OddNum odd={bettting.odds} color="primary" />
                                                        {/* <OddNum odd={americanOddsCalc(bettting.odds as number)} color="primary" /> */}
                                                    </Stack>
                                                    <Divider sx={{ my: 1 }} />
                                                </Stack>
                                            ))}
                                        </Transitions>
                                    )}
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography variant="body2">Total Odds</Typography>
                                        <Typography variant="body2" color="primary">
                                            {toNumber(item.odds)}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography variant="body2">Total Stack</Typography>
                                        <Stack direction="row" alignItems="center" spacing={0.5}>
                                            <Typography variant="body2" className="text-ellipse" color="white" sx={{ maxWidth: '100px' }}>
                                                {toNumber(item.stake)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography variant="body2">Payout</Typography>
                                        <Stack direction="row" alignItems="center" spacing={0.5}>
                                            <Typography variant="body2" className="text-ellipse" color="white" sx={{ maxWidth: '100px' }}>
                                                {toNumber(item.potential)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Divider sx={{ my: 1 }} />
                                    <Stack direction="row" justifyContent="space-between">
                                        <CopyToClipboard text={item._id} onCopy={() => enqueueSnackbar("Copied", { variant: 'success' })}>
                                            <IconButton
                                                sx={{
                                                    width: '30px',
                                                    height: '30px',
                                                    borderRadius: '30px'
                                                }}
                                            >
                                                <Typography variant="body2" color="white" sx={{ textTransform: 'uppercase' }}>
                                                    ID
                                                </Typography>
                                            </IconButton>
                                        </CopyToClipboard>
                                        <CopyToClipboard
                                            text={`${BASE_URL}/?b=${item.betsId}`}
                                            onCopy={() => enqueueSnackbar("Copied", { variant: 'success' })}
                                        >
                                            <IconButton
                                                sx={{
                                                    width: '30px',
                                                    height: '30px',
                                                    borderRadius: '30px'
                                                }}
                                            >
                                                <LinkIcon sx={{ transform: 'rotate(-45deg)' }} />
                                            </IconButton>
                                        </CopyToClipboard>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Transitions>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default MybetsActive;
