import axios from 'src/utils/axios';
import { API_PATH } from "src/config-global";

// ----------------------------------------------------------------------

export const deposit = async (payload: any) => {
  const URL = API_PATH.DEPOSIT;

  const response = await axios.post(URL, payload);
  return response.data;
}

export const withdraw = async (payload: any) => {
  const URL = API_PATH.WITHDRAW;

  const response = await axios.post(URL, payload);
  return response.data;
}