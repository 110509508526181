// @mui
import { Box, Button, Container, Divider, Grid, Stack, Typography } from '@mui/material';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
import Scrollbar from 'src/components/scrollbar/scrollbar';

import AuthDialog from 'src/sections/auth';

import { usePathname } from 'src/routes/hooks';
//
import Main from './main';
import Header from './header';
import Footer from './footer';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

const paymentBtns = [
  { route: "", img: '/assets/images/footer/usdt_eth.png' },
  { route: "", img: '/assets/images/footer/bitcoin.png' },
  { route: "", img: '/assets/images/footer/tron.png' },
  { route: "", img: '/assets/images/footer/litecoin.png' },
  { route: "", img: '/assets/images/footer/usdt.jpg' },
  { route: "", img: '/assets/images/footer/bnb.png' },
  { route: "", img: '/assets/images/footer/fasttoken.png' },
  { route: "", img: '/assets/images/footer/tron_usdt.png' },
  { route: "", img: '/assets/images/footer/fulgurpay.png' },
];

export default function DashboardLayout({ children }: Props) {
  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');
  const xsOnly = useResponsive('only', 'xs');

  const nav = useBoolean();
  const pathName = usePathname();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  const footerContent = pathName === "/" && (
    <Grid item xs={12} md={12} lg={12}>
      <Container maxWidth='lg' sx={{ maxWidth: '1670px !important' }}>
        <Divider orientation='horizontal' flexItem />
        {/* {renderContent} */}
        <Grid item mt={3} xs={12} md={3} display='flex' flexDirection='column'>
          <Typography variant='h5'>ABOUT US</Typography>
        </Grid>
        <Divider orientation='horizontal' flexItem />
        <Grid item xs={12} md={12} py={3} sx={{ textAlign: 'center' }}>
          <Typography fontSize='12px'>We are an official sports betting, slot and online casino site</Typography>

          {/* <Scrollbar sx={{ mt: 3 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={{ xs: 0.5, sm: 1 }}
              p={0.5}
              sx={{
                width: 1,
              }}
            >
              {paymentBtns.map((item, i) =>
                <Button
                  key={i}
                  variant='text'
                  sx={{ minWidth: '100px', px: 2, borderRadius: 1, flexDirection: 'column' }}
                >
                  <Box component='img' sx={{ height: '30px' }} src={item.img} />
                </Button>)}
            </Stack>
          </Scrollbar> */}

        </Grid>
        <Divider orientation='horizontal' flexItem />
        <Grid item xs={12} md={12} p={3}>
          <Stack direction="row" justifyContent='center'>
            <Typography fontSize="14px">
              Copyright © Roj365 2024 - All rights reserved
            </Typography>
          </Stack>
        </Grid>
      </Container>
    </Grid>
  )

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {renderHorizontal}

        <Main>
          {children}
          {footerContent}
        </Main>

        <AuthDialog />

        {xsOnly && <Footer />}
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {/* {lgUp ? renderNavMini : renderNavVertical} */}
          {renderHorizontal}

          <Main>
            {children}
            {footerContent}
          </Main>

          {xsOnly && <Footer />}
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {/* {renderNavVertical} */}
        {renderHorizontal}

        <Main>
          {children}
          {footerContent}
        </Main>

        {xsOnly && <Footer />}
      </Box>
    </>
  );
}
