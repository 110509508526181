import { useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Divider, IconButton, Typography, Button } from '@mui/material';
import Drawer, { drawerClasses } from '@mui/material/Drawer';

import { useResponsive } from 'src/hooks/use-responsive';
import { useRouter } from 'src/routes/hooks';

import BetSlipTabs from "src/components/betslip/Tabs";

// theme
import { paper } from 'src/theme/css';
import { useSettingsContext } from '../../components/settings';
//
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';

type PropsType = {
  status: boolean;
  closeModal: () => void;
}

const BetSlipDrawer = ({ status, closeModal }: PropsType) => {
  const theme = useTheme();
  const router = useRouter();

  const settings = useSettingsContext();
  const isMini = settings.themeLayout === 'mini';
  const mdUp = useResponsive('up', 'md');

  const handleClickMenu = (path: string) => {
    closeModal();
    router.push(path);
  };

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1, textTransform: "uppercase" }}>
        BetSlip
      </Typography>

      <IconButton onClick={closeModal}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  return (
    <Drawer
      anchor="right"
      open={status}
      onClose={closeModal}
      slotProps={{
        backdrop: { invisible: true },
      }}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          ...paper({ theme, bgcolor: theme.palette.background.default }),
          width: (mdUp ? '40%' : 1),
          height: 1,
          maxWidth: 1
        },
      }}
    >
      {renderHead}

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Scrollbar sx={{ px: 2.5, py: 2, display: "flex", flexDirection: "column", gap: 1 }}>
        {/* <Button
          variant="outlined"
          startIcon={<Iconify icon="eos-icons:virtual-guest" />}
          sx={{ width: 1, justifyContent: "start" }}
        >
          List
        </Button> */}
        <BetSlipTabs closeModal={closeModal} />
      </Scrollbar>
    </Drawer>
  );
}

export default BetSlipDrawer;