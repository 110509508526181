import { useEffect, useState } from 'react';
// @mui
import { useTheme, alpha } from '@mui/material/styles';
import { Alert, Box, Stack, Divider, IconButton, Typography, Button } from '@mui/material';
import Drawer, { drawerClasses } from '@mui/material/Drawer';

import { useResponsive } from 'src/hooks/use-responsive';
import { useRouter } from 'src/routes/hooks';

// theme
import { paper } from 'src/theme/css';
import { useSettingsContext } from '../../components/settings';
//
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';

type PropsType = {
  status: boolean;
  closeModal: () => void;
}

const FavoriteDrawer = ({ status, closeModal }: PropsType) => {
  const theme = useTheme();
  const router = useRouter();

  const settings = useSettingsContext();
  const isMini = settings.themeLayout === 'mini';
  const mdUp = useResponsive('up', 'md');

  const [activeTab, setActiveTab] = useState("sport");

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1, textTransform: "uppercase" }}>
        Favorites
      </Typography>

      <IconButton onClick={closeModal}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  return (
    <Drawer
      anchor="right"
      open={status}
      onClose={closeModal}
      slotProps={{
        backdrop: { invisible: true },
      }}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          ...paper({ theme, bgcolor: theme.palette.background.default }),
          width: (mdUp ? '40%' : 1),
          height: 1,
          maxWidth: 1
        },
      }}
    >
      {renderHead}

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Scrollbar sx={{ px: 2.5, py: 2, display: "flex", flexDirection: "column", gap: 1 }}>
        <Stack
          direction="row"
          gap={0.5}
          sx={{
            p: 0.25,
            my: 2,
            width: 1,
            borderRadius: 1,
            bgcolor: alpha(theme.palette.grey[500], 0.04),
            border: `dashed 1px ${theme.palette.divider}`
          }}
        >
          <Button variant="outlined" sx={{ width: 1, ...(activeTab === "sport" && { backgroundColor: alpha(theme.palette.grey[400], 0.8) }) }} onClick={() => setActiveTab("sport")}>Sport</Button>

          <Button variant="outlined" sx={{ width: 1, ...(activeTab === "casino" && { backgroundColor: alpha(theme.palette.grey[400], 0.8) }) }} onClick={() => setActiveTab("casino")}>Casino</Button>
        </Stack>

        <Alert severity="error" sx={{ mb: 3, mt: 1, opacity: 0.7 }}>
          {`You don't have favorite games. To add some, click the start icon near the game name.`}
        </Alert>
      </Scrollbar>
    </Drawer>
  );
}

export default FavoriteDrawer;