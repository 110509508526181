// @mui
import Box, { BoxProps } from '@mui/material/Box';
// hooks
// import { useResponsive } from 'src/hooks/use-responsive';
// components
// import { useSettingsContext } from 'src/components/settings';
//
import { HEADER, } from '../config-layout';
// import { HEADER, NAV } from '../config-layout';

// ----------------------------------------------------------------------

// const SPACING = 8;

export default function Main({ children, sx, ...other }: BoxProps) {
  // const settings = useSettingsContext();

  // const lgUp = useResponsive('up', 'lg');

  return (
    <Box
      component="main"
      sx={{
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        pt: `${HEADER.H_MOBILE * 2 - 20}px`,
        // pb: 5,
      }}
    >
      {children}
    </Box>
  );
}
