import React, { createContext, useEffect } from 'react';
import axios from 'src/utils/axios';
import { getDate } from "src/utils/filter";
import { API_PATH } from 'src/config-global';
import {
  ApiContextType,
  UserRegisterType,
  UserLoginType,
} from 'src/types';
import { useDispatch, useSelector } from 'src/store';
import { signout } from 'src/store/reducers/auth';
import { CircularProgress } from '@mui/material';

// import { edit } from 'src/store/reducers/auth';

const ApiContext = createContext<ApiContextType | null>(null);
/* eslint-disable */
export const ApiProvider = ({ children }: { children: React.ReactElement }) => {
  const dispatch = useDispatch();
  const state = useSelector((store) => store.auth);
  const { user, currencyId, balanceId, code } = state;
  console.log(currencyId, "========>currencyId");
  const userId = user?._id;
  const userName = user?.username;
  // const initialize = async () => {
  //   const response = await axios.post(API_PATH.AUTH_CHECK);
  //   return dispatch(edit(!response ? {} : response.data));
  // };

  // useEffect(() => {
  //   initialize();
  // }, []);

  const register = async (data: UserRegisterType) => {
    return await axios.post(API_PATH.AUTH_REGISTER, data);
  };

  const login = async (data: UserLoginType) => {
    return await axios.post(API_PATH.AUTH_LOGIN, data);
  };


  const logout = () => {
    // @ts-ignore
    dispatch(signout());
  };

  const forgotPassword = async (email: string) => {
    const res = await axios.post("users/forgot", {
      email,
    });
    return res;
  };

  const changePassword = async (data: any) => {
    const res = await axios.post("users/c-password", data);
    return res;
  };

  const checkAddress = async (publicAddress: string) => {
    const res = await axios.post("users/a-check", { publicAddress });
    return res;
  };

  const signInAddress = async (publicAddress: string, signature: string) => {
    const res = await axios.post("users/a-signin", {
      publicAddress,
      signature,
    });
    return res;
  };

  const signUpAddress = async (publicAddress: string) => {
    const res = await axios.post("users/a-signup", {
      rReferral: code,
      publicAddress,
    });
    return res;
  };

  const updateUserInfo = async (info: any) => {
    const res = await axios.post("users/info", { ...info, userId });
    return res;
  };

  const addNewItlAcc = async (name: string, mainUserID: string) => {
    const res = await axios.post("users/add-new-itlacc", { name, mainUserID, userId });
    return res;
  }
  const loadAllItlAcc = async (mainUserID: string) => {
    const res = await axios.post("users/load-all-itlacces", { mainUserID, userId });
    return res;
  }


  const getReferral = async () => {
    const res = await axios.post("users/referral", { userId });
    return res;
  };

  const getTransactions = async () => {
    const res = await axios.post("payments/get-transaction", {
      userId,
    });
    return res;
  };

  const getLeaderboard = async (
    page: number,
    pageSize: number,
    userid: any = null,
    date: any
  ) => {
    const res = await axios.post("users/ranking", {
      userId,
      userName,
      pageSize,
      page: page + 1,
      filter: userid,
      date,
    });
    return res;
  };

  const sendMessage = async (msg: string) => {
    const res = await axios.post("users/msg", { msg, userId, userName });
    return res;
  };

  const getMessage = async () => {
    const res = await axios.get("users/getmsg");
    return res;
  };

  const getBalances = async () => {
    const res = await axios.post("payments/get-balance", { userId });
    return res;
  };

  const getCurrency = async () => {
    const res = await axios.post("payments/get-currency", {});
    return res;
  };

  const addCurrency = async (currency: string) => {
    const res = await axios.post("payments/add-currency", {
      userId,
      currency,
    });
    return res;
  };

  const changeCurrency = async (currency: string) => {
    const res = await axios.post("payments/use-currency", {
      userId,
      currency,
    });
    return res;
  };

  const depositMetamask = async (transaction: any) => {
    const res = await axios.post("payments/m-deposit", {
      userId,
      balanceId,
      currencyId,
      ...transaction,
    });
    return res;
  };

  const withdrawal = async (
    address: string,
    method: number,
    amount: number
  ) => {
    const res = await axios.post("payments/withdrawal", {
      userId,
      currencyId,
      balanceId,
      address,
      method,
      amount,
    });
    return res;
  };

  const cancelWithdrawal = async (_id: string) => {
    const res = await axios.post("payments/c-withdrawal", {
      userId,
      _id,
    });
    return res;
  };

  const betSport = async (data: any, type: string | number, stake: number) => {
    const res = await axios.post("sports/bet", {
      data,
      type,
      stake,
      userId,
      currency: currencyId,
    });
    return res;
  };

  const getMybets = async (status: string) => {
    const res = await axios.post("sports/history", {
      status,
      userId,
    });
    return res;
  };

  const getMyPvpbets = async (status: string) => {
    const res = await axios.post("sports/pvp-history", {
      status,
      userId,
    });
    return res;
  };

  const getAllSportsProfitByCurrency = async (currency: string) => {
    let date = getDate()
    const res = await axios.post('sports/getAllSportsProfitByCurrency', { date, currency })
    return res;
  }

  const getSportsProfit = async (type: string) => {
    const date = getDate(type);
    const res = await axios.post("sports/getprofit", { date });
    return res;
  };

  const getAllSportsProfit = async () => {
    const res = await axios.get('sports/getAllSportsProfit')
    return res;
  }

  const getV2SportsProfit = async (type: string) => {
    const date = getDate(type);
    const res = await axios.post("sports/getv2profit", { date });
    return res;
  };

  const getAllV2SportsProfit = async () => {
    const res = await axios.get("sports/getAllV2SportsProfit");
    return res;
  };


  const getAllV2SportsProfitByCurrency = async (currency: string) => {
    let date = getDate()
    const res = await axios.post('sports/getAllV2SportsProfitByCurrency', { date, currency })
    return res;
  }

  const getUserSportsProfit = async (type: string) => {
    const date = getDate(type);
    const res = await axios.post("sports/getuserprofit", {
      date,
      userId,
    });
    return res;
  };

  const getAllCasinosProfit = async () => {
    let date = getDate('July')
    const res = await axios.post('casinos/getAllCasinosProfit', { date })
    return res;
  }

  const getCasinoHistory = async (type: number, perPage: number) => {
    const res = await axios.post("games/history", {
      type,
      perPage,
      userId,
    });
    return res;
  };

  const getCasinoGames = async (id: string, pid: string, gname: string, sort: number, page: number, pageSize: number) => {
    const res = await axios.post("casinos/get-gamelists", {
      id,
      pid,
      gname,
      sort,
      page,
      pageSize
    });
    return res;
  };

  const getCasinoGamesByCategory = async (id: any[], gname: string, sort: number) => {
    const res = await axios.post("casinos/get-gamelistsbycategory", {
      id,
      gname,
      sort
    });
    return res;
  };

  const getCasinoGameDetail = async (id: string) => {
    const res = await axios.post("casinos/get-gamedetail", {
      id,
    });
    return res;
  };

  const getCasinoProviders = async (id: string) => {
    const res = await axios.post("casinos/get-providers", {
      id,
    });
    return res;
  };

  const getCasinosProfit = async (type: string) => {
    const date = getDate(type);
    const res = await axios.post("casinos/getprofit", { date });
    return res;
  };

  const getAllCasinoProfitByCurrency = async (currency: string) => {
    let date = getDate()
    const res = await axios.post("casinos/getAllCasinoProfitByCurrency", { date, currency });
    return res;
  };

  const getUserCasinosProfit = async (type: string) => {
    const date = getDate(type);
    const res = await axios.post("casinos/getuserprofit", {
      date,
      userId,
    });
    return res;
  };

  const uploadFile = async (data: FormData) => {
    const res = await axios.post("files/", data);
    return res;
  };

  const deleteFile = async (uri: string) => {
    const res = await axios.post("files/delete", { uri });
    return res;
  };


  if (state.isInitialized !== undefined && !state.isInitialized) {

    return <CircularProgress />;
  }





  return (
    <ApiContext.Provider
      value={{
        // initialize,
        register,
        login,
        logout,
        forgotPassword,
        checkAddress,
        signInAddress,
        signUpAddress,
        changePassword,
        updateUserInfo,
        addNewItlAcc,
        loadAllItlAcc,
        sendMessage,
        getMessage,
        getReferral,
        getTransactions,
        getLeaderboard,
        getBalances,
        getCurrency,
        addCurrency,
        changeCurrency,
        depositMetamask,
        withdrawal,
        cancelWithdrawal,
        betSport,
        getMybets,
        getMyPvpbets,
        getAllSportsProfit,
        getAllSportsProfitByCurrency,
        getSportsProfit,
        getV2SportsProfit,
        getAllV2SportsProfitByCurrency,
        getAllV2SportsProfit,
        getUserSportsProfit,
        getCasinoHistory,
        getCasinoGames,
        getCasinoGamesByCategory,
        getCasinoGameDetail,
        getCasinoProviders,
        getCasinosProfit,
        getAllCasinoProfitByCurrency,
        getUserCasinosProfit,
        getAllCasinosProfit,
        uploadFile,
        deleteFile,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export default ApiContext;
