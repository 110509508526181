import { useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Divider, IconButton, Typography, Button, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import Drawer, { drawerClasses } from '@mui/material/Drawer';

import { useResponsive } from 'src/hooks/use-responsive';
import { useRouter } from 'src/routes/hooks';

import LanguagePopover, { allLangs } from 'src/layouts/_common/language-popover';
// theme
import { paper } from 'src/theme/css';
import { useSettingsContext } from '../settings';
//
import Iconify from '../iconify';
import Scrollbar from '../scrollbar';

type PropsType = {
  status: boolean;
  closeModal: () => void;
};

const formatTimes = [
  { label: "12h", value: "12h" },
  { label: "24h", value: "24h" }
];

const formatOdds = [
  { label: "Decimal", value: "decimal" },
  { label: "Fractional", value: "fractional" },
  { label: "American", value: "american" },
  { label: "HongKong", value: "hongkong" },
];

const SettingDrawer = ({ status, closeModal }: PropsType) => {
  const theme = useTheme();
  const router = useRouter();

  const settings = useSettingsContext();
  const isMini = settings.themeLayout === 'mini';
  const mdUp = useResponsive('up', 'md');

  const [language, setLanguage] = useState("en");
  const [timeFormat, setTimeFormat] = useState("12h");

  const handleLanguageChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value as string);
  };

  const handleTimeChange = (event: SelectChangeEvent) => {
    setTimeFormat(event.target.value as string);
  };

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1, textTransform: "uppercase" }}>
        Settings
      </Typography>

      <IconButton onClick={closeModal}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  return (
    <Drawer
      anchor="right"
      open={status}
      onClose={closeModal}
      slotProps={{
        backdrop: { invisible: true },
      }}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          ...paper({ theme, bgcolor: theme.palette.background.default }),
          width: (mdUp ? '40%' : 1),
          height: 1,
          maxWidth: 1
        },
      }}
    >
      {renderHead}

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Scrollbar sx={{ px: 2.5, py: 2, display: "flex", flexDirection: "column", gap: 1 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Language</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            label="Language"
            onChange={handleLanguageChange}
            sx={{
              '& .MuiSelect-select': {
                display: "flex",
                alignItems: "center"
              }
            }}
          >
            {allLangs.map((lang, index) => <MenuItem key={index} value={lang.value}><Iconify icon={lang.icon} mr={1} />{lang.label}</MenuItem>)}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 3 }}>
          <InputLabel id="demo-simple-select-label">Time Format</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={timeFormat}
            label="TimeFormat"
            onChange={handleTimeChange}
            sx={{
              '& .MuiSelect-select': {
                display: "flex",
                alignItems: "center"
              }
            }}
          >
            {formatTimes.map((time, index) => <MenuItem key={index} value={time.value}>{time.label}</MenuItem>)}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 3 }}>
          <InputLabel id="demo-simple-select-label">Odds Format</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={timeFormat}
            label="OddsFormat"
            // onChange={handleTimeChange}
            sx={{
              '& .MuiSelect-select': {
                display: "flex",
                alignItems: "center"
              }
            }}
          >
            {formatOdds.map((time, index) => <MenuItem key={index} value={time.value}>{time.label}</MenuItem>)}
          </Select>
        </FormControl>
      </Scrollbar>
    </Drawer>
  );
}

export default SettingDrawer;