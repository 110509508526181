import { createSlice } from '@reduxjs/toolkit';
import { MarketsProps } from 'src/types/sports';

const initialState: MarketsProps = {
    moneyLine: false,
    handicap: false,
    totals: false
};

const markets = createSlice({
    name: 'markets',
    initialState,
    reducers: {
        setMarketStatus(state, action) {
            state.moneyLine = action.payload.moneyLine;
            state.handicap = action.payload.handicap;
            state.totals = action.payload.totals;
        },
    }
});

export default markets.reducer;

export const { setMarketStatus } = markets.actions;
