import { InitialLoginContextProps } from 'src/types/auth';
import { createSlice } from '@reduxjs/toolkit';

const initialCurrency = {
    _id: '',
    icon: process.env.REACT_APP_CURRENCY_ICON as string,
    symbol: process.env.REACT_APP_CURRENCY as string,
    minBet: 1000,
    maxBet: 100000,
    price: 0.1
};

const initialUser = {
    _id: '',
    email: '',
    username: '',
    iReferral: '',
    avatar: ''
};

const initialState: InitialLoginContextProps = {
    isInitialized: true,
    isLoggedIn: false,
    code: '',
    betsId: '',
    token: '',
    balance: 0,
    balanceId: '',
    currencyId: '',
    user: initialUser,
    currency: initialCurrency,
    adminAddress: '',
    selectedAccount: ''
};

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        signin(state, action: any) {
            const { balance, user, session, adminAddress } = action.payload!;

            state.user = user;
            state.token = session.accessToken;
            state.balance = balance.balance;
            state.balanceId = balance._id;
            state.currency = balance.currency;
            state.currencyId = balance.currency._id;
            state.isLoggedIn = true;
            state.isInitialized = true;
            state.adminAddress = adminAddress;
            state.selectedAccount = user._id;
        },

        UpdateInfo(state, action: any) {
            state.user = action.payload;
        },

        UpdateBalance(state, action: any) {
            state.balance = action.payload;
        },

        UpdateBalances(state, action: any) {
            const balance = action.payload!;
            state.balance = balance.balance;
            state.balanceId = balance._id;
            state.currency = balance.currency;
            state.currencyId = balance.currency._id;
            state = { ...state };
        },

        SetCode(state, action: any) {
            state.code = action.payload;
        },

        SetBetsId(state, action: any) {
            state.betsId = action.payload;
        },

        signout(state, action: any) {
            state.token = '';
            state.balance = 0;
            state.balanceId = '';
            state.currencyId = '';
            state.user = initialUser;
            state.currency = initialCurrency;
            state.isLoggedIn = false;
            state.isInitialized = true;
            state.selectedAccount = '';
            state = { ...state };
            if (
                window.location.pathname.toString().indexOf('blackjack') !== -1 ||
                window.location.pathname.toString().indexOf('roulette') !== -1
            ) {
                window.location.reload();
            }
        },
        switchAccount(state, action: any) {
            const account = action.payload!;
            state.selectedAccount = account;
            state = { ...state };
        }
    }
});

export default auth.reducer;

export const { signin, signout, UpdateInfo, UpdateBalances, UpdateBalance, SetCode, SetBetsId, switchAccount } = auth.actions;
