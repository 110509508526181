import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { enqueueSnackbar } from 'notistack';

import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Menu,
    MenuItem,
    MenuProps,
    OutlinedInput,
    Paper,
    Stack,
    Typography,
    alpha,
    styled,
    useTheme
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { BetslipProps } from 'src/types/sports';
import { LeagueHoverColor, LeagueItemColor, MainColor } from 'src/types/colors';

import useApi from 'src/hooks/use-api';

import { toNumber } from 'src/utils/number';
// import { abbreviate, addRemoveBetslip } from 'src/utils/sports';

import { useDispatch, useSelector } from 'src/store';
import { clearAll, setBetslip } from 'src/store/reducers/sports';
import { set } from 'src/store/reducers/drawer';

import Transitions from 'src/pages/sports/components/Transitions';
import OddNum from 'src/pages/sports/components/OddNum';
import { BASE_URL } from 'src/config-global';

import BetSlipItem from './slipItem';
import Iconify from '../iconify';


const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        background: "black",
        borderRadius: 6,
        marginTop: theme.spacing(1),
        maxHeight: 200,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        // '& .MuiMenu-list': {
        //     padding: '4px 0',
        // },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: "12px !important",
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const menuItems = [
    {
        id: 0, label: "Always ask"
    },
    {
        id: 1, label: "Accept Higher odds"
    },
    {
        id: 2, label: "Accept Any odds"
    },
];
const types = [
    {
        id: "single",
        label: "Signle"
    },
    {
        id: "multi",
        label: "Multiple"
    }
]

interface menuProps {
    id: number | string,
    label: string
}
interface propsType {
    closeModal?: () => void
}
const BetTabs = (props: propsType) => {
    const { closeModal } = props;
    const params = useParams();
    const Api = useApi();
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { betslipData } = useSelector((state) => state.sports);
    const { user, currency, isLoggedIn } = useSelector((state) => state.auth);
    const [result, setResult] = useState<any>([]);
    const [activeTab, setActiveTab] = useState<menuProps>(menuItems[0]);
    const [betType, setBetType] = useState<menuProps>(types[0]);
    const [amount, setAmount] = useState<number>(0);
    const [aError, setAError] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [isbet, setIsbet] = useState<boolean>(false);


    const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);
    // ----- bt -> bet Type
    const [btAnchorEl1, setbtAnchorEl1] = useState<null | HTMLElement>(null);
    const openbt = Boolean(btAnchorEl1);

    const viewMyBets = () => {
        navigate('/my-bets');
        console.log(closeModal, "=======>closeModal");
        if (closeModal !== undefined) {
            closeModal();
        }
    }


    const handleClick1 = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };
    const handleClickBt = (event: React.MouseEvent<HTMLElement>) => {
        if (betslipData.length >= 2) {
            setbtAnchorEl1(event.currentTarget);
        }
    };
    const handleCloseBt = () => {
        setbtAnchorEl1(null);
    };

    const tabChangeHandler = (item: menuProps) => {
        setActiveTab(item);
        clearHandler();
        handleClose1();
    };

    const btTypechangeHandler = (item: menuProps) => {
        setBetType(item);
        clearHandler();
        handleCloseBt();
    }

    const clearAllHandler = () => {
        dispatch(clearAll());
        clearHandler();
    };

    const clearHandler = () => {
        setAError('');
        setError('');
        setResult([]);
        setIsbet(false);
        setAmount(0);

    };

    const multiAmountHandler = (value: any) => {
        setAmount(value);
    };

    // const totalOdds = betslipData.reduce((sum, { price }) => {
    //     sum += Number(price);
    //     return sum;
    // }, 0);

    const multiplyOdds = betslipData.reduce((sum, { price }) => {
        sum *= Number(price);
        return sum;
    }, 1);

    // const totalStake = betslipData.reduce((sum, { stake }) => {
    //     sum += Number(stake);
    //     return sum;
    // }, 0);

    // const totalPayout = betslipData.reduce((sum, { stake, odds }) => {
    //     sum += Number(stake) * Number(odds);
    //     return sum;
    // }, 0);

    // const multiplyMany = betslipData.reduce((sum, { stake, odds }) => {
    //     sum *= Number(odds);
    //     return sum;
    // }, amount);

    const maxBet = currency?.maxBet || 0;
    const minBet = currency?.minBet || 0;
    // const odds = activeTab === 0 ? totalOdds : multiplyOdds;
    // const stake = activeTab === 0 ? totalStake : amount;
    // const potential = activeTab === 0 ? totalPayout : multiplyMany;
    // const isBet = balance > 0 && balance >= stake;

    const betHandler = () => {
        setError('');
        setAError('');
        if (!betslipData.length) return;
        const userId = user._id;

        if (betType.id === "single") {
            const validAmount = betslipData.find(item => item.bet_amount === 0 || !item.bet_amount);
            if (validAmount) {
                enqueueSnackbar('Please Input the Bet Amount.', { variant: 'error' });
                return;
            }
        }
        if (betType.id === "multi") {
            if (!amount) {
                enqueueSnackbar('Please Input the Bet Amount.', { variant: 'error' });
                return;
            }
        }


        Api.betSport(betslipData, betType.id, amount)
            .then(({ data }) => {
                console.log(data)
                clearAllHandler();

                setLoading(false);
                enqueueSnackbar('Submit successfully!', { variant: 'success' });
            })
            .catch(() => {
                setLoading(false);
            });
    };


    return (
        <PerfectScrollbar component="div">
            <Stack direction="row" alignItems="center" justifyContent="space-between" component={Card} px={1} sx={{ borderRadius: "5px", mb: 0.5 }}>
                <Stack direction='row' component={Paper} p={0.3}>
                    <Button
                        id="demo-customized-button"
                        aria-controls={open1 ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open1 ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        disableRipple
                        size="small"
                        onClick={handleClick1}
                        endIcon={open1 ? <Iconify icon="mingcute:up-line" /> : <Iconify icon="mingcute:down-line" />}
                        startIcon={<Iconify icon="ant-design:setting-filled" />}
                        sx={{
                            backgroundColor: "transparent",
                            color: "white",
                            alignItems: "center",
                            px: 1,
                            borderRadius: "3px !important",
                            '&:hover': {
                                backgroundColor: 'transparent',
                                color: "white"
                            }
                        }}
                    >
                        {activeTab?.label}
                    </Button>
                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl1}
                        open={open1}
                        onClose={handleClose1}
                    >
                        {menuItems.map(item =>
                            <MenuItem sx={{
                                borderRadius: "3px",
                                background: LeagueItemColor,
                                fontSize: 12,
                                '&:hover': { background: LeagueHoverColor },
                                marginBottom: "2px !important"
                            }} onClick={() => tabChangeHandler(item)} disableRipple>
                                {item.label}
                            </MenuItem>
                        )}
                    </StyledMenu>
                </Stack>

            </Stack>
            {betslipData.length > 0 &&
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    component={Card}
                    px={1}
                    sx={{ borderRadius: "5px", mb: 0.5 }}
                >
                    <Stack direction='row' component={Paper} p={0.3}>
                        <Button
                            id="demo-customized-button"
                            aria-controls={openbt ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openbt ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            disableRipple
                            size="small"
                            onClick={handleClickBt}
                            endIcon={betslipData.length > 1 ? openbt ? <Iconify icon="mingcute:up-line" /> : <Iconify icon="mingcute:down-line" /> : null}
                            sx={{
                                backgroundColor: "transparent",
                                color: "white",
                                alignItems: "center",
                                px: 1,
                                borderRadius: "3px !important",
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    color: "white"
                                }
                            }}
                        >
                            {betType?.label}
                        </Button>
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={btAnchorEl1}
                            open={openbt}
                            onClose={handleCloseBt}
                        >
                            {types.map(item =>
                                <MenuItem sx={{
                                    borderRadius: "3px",
                                    background: LeagueItemColor,
                                    fontSize: 12,
                                    '&:hover': { background: LeagueHoverColor },
                                    marginBottom: "2px !important"
                                }} onClick={() => btTypechangeHandler(item)} disableRipple>
                                    {item.label}
                                </MenuItem>
                            )}
                        </StyledMenu>
                    </Stack>
                    <Box component="a" onClick={clearAllHandler}
                        sx=
                        {{
                            cursor: "pointer",
                            textDecoration: "nunderline",
                            fontSize: 12
                        }}>
                        Remove All
                    </Box>
                </Stack>
            }
            {
                isbet ? (
                    <>
                        {/* <PerfectScrollbar component="div"> */}
                        {(result as any[]).map((item: any) =>
                            ((item && item.bets) as any[]).map((bet: any, key) => (
                                <Transitions key={key} in direction="left" type="slide">
                                    <Card
                                        sx={{
                                            mb: 1,
                                            background: "red"
                                        }}
                                        style={{ borderRadius: '4px' }}
                                    >
                                        <CardHeader
                                            sx={{
                                                background:
                                                    // bet.finished === true || bet.updated === true
                                                    bet.finished === true
                                                        ? theme.palette.error.light
                                                        : theme.palette.success.darker,
                                                // : theme.palette.success.light,
                                                p: 1.5,
                                                '& .MuiCardHeader-title': {
                                                    fontSize: '14px'
                                                },
                                                '& svg': {
                                                    fontSize: '16px'
                                                },
                                            }}
                                            title={
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    {/* {bet.finished === true || bet.updated === true ? ( */}
                                                    {bet.finished === true ? (
                                                        <CloseIcon color="error" />
                                                    ) : (
                                                        <CheckIcon color="success" />
                                                    )}
                                                    <Typography
                                                        onClick={() => navigate(`/sports/pre-match/${params.sportsId}/${params.tabId}/${bet.eventId}`)}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        {`${bet.HomeTeam} - ${bet.AwayTeam}`}
                                                    </Typography>
                                                </Stack>
                                            }
                                        />
                                        <CardContent sx={{ background: theme.palette.grey[700], p: 1.5 }} style={{ paddingBottom: '12px' }}>
                                            <Typography variant="body2">
                                                {bet.marketName && bet.marketName}
                                            </Typography>
                                            <Stack direction="row" justifyContent="space-between">
                                                <Typography variant="body2" color="white">
                                                    {bet.oddName}
                                                </Typography>
                                                <OddNum odd={bet.odds} color="primary" />
                                            </Stack>
                                            {/* {(bet.finished === true || bet.updated === true) && ( */}
                                            {(bet.finished === true) && (
                                                <Typography color="error">
                                                    Bet rejected. Odds changed.
                                                </Typography>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Transitions>
                            ))
                        )}
                        {/* </PerfectScrollbar> */}
                        <Stack sx={{ background: theme.palette.grey[700], p: 2 }}>
                            <Button sx={{ mt: 1 }} variant="contained" fullWidth onClick={viewMyBets}>
                                View My Bets
                            </Button>
                        </Stack>
                    </>
                ) : (
                    <>
                        {/* <PerfectScrollbar component="div"> */}
                        {betslipData.length > 0 ? betslipData.map((item, key) => (
                            <BetSlipItem betType={betType} item={item} />
                        ))
                            :
                            <Stack minHeight="120px" direction="row" justifyContent="center" alignItems="center">
                                <Typography fontSize={12}>Your betslip is empty</Typography>
                            </Stack>
                        }
                        {!isLoggedIn &&
                            <Alert severity="warning" sx={{
                                fontSize: 12,
                                background: "#aa7f0033",
                                borderRadius: "2px",
                                mb: "4px",
                                p: "2px 10px",
                                '& svg': {
                                    width: "20px"
                                }
                            }}>To place your bet,Please Sign in or Register</Alert>
                        }
                        {/* </PerfectScrollbar> */}
                        <Stack sx={{ background: "transparent", p: 1 }}>
                            {(betType.id === "multi" && betslipData.length > 0) && (

                                <Stack direction="row" flexDirection="column" gap="5px">
                                    <Stack direction="row" justifyContent="space-between" mt={1}>
                                        <Typography variant="body2" color="white">
                                            Odds
                                        </Typography>
                                        <Typography className="text-ellipse" variant="body2" color="primary" sx={{ maxWidth: '100px' }}>
                                            {toNumber(multiplyOdds)}
                                        </Typography>
                                    </Stack>
                                    <OutlinedInput
                                        id="betamount"
                                        size="small"
                                        type="number"
                                        placeholder='Enter stake'
                                        sx={{
                                            borderRadius: 1,
                                            '& fieldset': {
                                                borderRadius: 1
                                            }
                                        }}
                                        value={amount || ''}
                                        onChange={(e) => multiAmountHandler(e.target.value)}
                                    />
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography fontSize={12}>
                                            Possible win :
                                        </Typography>
                                        <Typography fontSize={12} color={MainColor}>
                                            {toNumber(multiplyOdds * amount)} $
                                        </Typography>
                                    </Stack>
                                </Stack>
                            )}
                            {aError && (
                                <Alert
                                    variant="outlined"
                                    severity="error"
                                    sx={{
                                        mt: 1,
                                        borderColor: theme.palette.error.main,
                                        '& .MuiAlert-message': {
                                            p: 0
                                        }
                                    }}
                                >
                                    {aError}
                                </Alert>
                            )}
                            {error && (
                                <Alert
                                    variant="outlined"
                                    severity="error"
                                    sx={{
                                        mt: 1,
                                        borderColor: theme.palette.error.main,
                                        '& .MuiAlert-message': {
                                            p: 0
                                        }
                                    }}
                                >
                                    {error}
                                </Alert>
                            )}
                            <Button sx={{ mt: 1 }} variant="contained" fullWidth
                                onClick={betHandler}
                                disabled={!isLoggedIn || !betslipData.length}>
                                BET NOW
                            </Button>
                        </Stack>
                    </>
                )
            }
        </PerfectScrollbar >
    );
};

export default BetTabs;
